<!-- This component is specifically designed for the Fan Mode property (ir-thermostat-fan-mode) of RMT_ZXT800 -->
<template>
  <v-slider v-model="value"
            :disabled="!device.gatewayOnline || disabled"
            class="non-flip pt-10 pb-3 px-2 mode-control-slider"
            color="success"
            track-color="success lighten-3"
            hide-details
            min="0"
            max="3"
            thumb-label="always"
            :append-icon="increaseIcon"
            :prepend-icon="decreaseIcon"
            @click:append="increase"
            @click:prepend="decrease"
            @change="updateProp"
            step="1"
            ticks
            tick-size="6"
            tick-color="success"
            :tick-labels="tickLabels">
    <template v-slot:thumb-label>
      <span class="font-size-05 ma-0 material-symbols-outlined">mode_fan</span>
    </template>
  </v-slider>
</template>

<script>

import deviceProperties from "@/config/deviceProperties.json";

export default {
  name: 'FanModeInput',

  components: {},

  props: {
    device: Object,
    actuator: Object,
    dense: Boolean,
    disabled: Boolean
  },

  data: function () {
    return {
      value: null,
      allowValueChange: true,
      cooldown: 3000,
      changeTimeout: null,
      loading: false,
      valueMapping: {
        0: 1,
        1: 5,
        2: 3,
        3: 0
      }
    }
  },

  computed: {
    increaseIcon() {
      return deviceProperties[this.actuator?.name]?.increaseIcon
    },

    decreaseIcon() {
      return deviceProperties[this.actuator?.name]?.decreaseIcon
    },

    tickLabels() {
      return [
        this.$t("ir-controller-card.fan-mode-slider.low"),
        this.$t("ir-controller-card.fan-mode-slider.off"),
        this.$t("ir-controller-card.fan-mode-slider.high"),
        this.$t("ir-controller-card.fan-mode-slider.auto")
      ]
    }
  },

  methods: {
    increase() {
      this.value += this.stepAmount
      this.updateProp()
    },

    decrease() {
      this.value -= this.stepAmount
      this.updateProp()
    },

    updateProp() {
      this.loading = true
      this.allowValueChange = false
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout)
      }
      this.changeTimeout = setTimeout(() => {
        this.allowValueChange = true
        if (this.value === this.actuator?.value) {
          // No change, no update needed
          this.loading = false
        }
      }, this.cooldown)

      this.$rhRequest.sendPost({
        endpoint: 'devices/update-property',
        data: {
          deviceId: this.device.id,
          property: this.actuator.name,
          value: this.valueMapping[this.value]
        }
      }, () => {
        this.loading = false
      }, (error) => {
        console.error(error)
        if (error.code !== 'ECONNABORTED') {
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        }
        this.loading = false
      })
    },

    getDeviceValue() {
      let actuatorValue = this.device?.actuators?.find(a => a.name === this.actuator?.name)?.value
      return Object.keys(this.valueMapping).find((key) => this.valueMapping[key] === actuatorValue)
    }
  },

  mounted() {
    // initialize "value" after component is initialized
    this.value = this.getDeviceValue();
  },

  watch: {
    device() {
      if (this.allowValueChange) {
        this.value = this.getDeviceValue()
      }
    }
  }
}

</script>
