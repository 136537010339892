<template>
  <fullscreen-overlay-frame :title="$t('add-kaadas-pin-dialog.title').toString()"
                            icon="lock"
                            color="primary"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>
      <v-alert type="warning"
               text
               class="font-size-03 mb-5"
               border="left">
        {{ $t('add-kaadas-pin-dialog.hint') }}
      </v-alert>
      <v-form v-model="valid" ref="form">
        <v-text-field outlined
                      v-model="pinName"
                      :rules="[rule.required, rule.maxLengthName]"
                      type="text"
                      required
                      :label="$t('add-kaadas-pin-dialog.name-textfield.label')">
        </v-text-field>
        <v-text-field class="mt-1"
                      outlined
                      v-model="pinValue"
                      :rules="[rule.minLength, rule.number, rule.maxLength, rule.ascendingNumbers, rule.descendingNumbers, rule.repeatedNumbers]"
                      :type="passwordVisible ? 'text' : 'password'"
                      required
                      :label="$t('add-kaadas-pin-dialog.pin-textfield.label')">
          <template v-slot:append>
            <v-icon v-if="passwordVisible" @click="passwordVisible = !passwordVisible">visibility</v-icon>
            <v-icon v-else @click="passwordVisible = !passwordVisible">visibility_off</v-icon>
          </template>
        </v-text-field>

      </v-form>
      <div id="add-kaadas-pin-save-screen" v-if="saveCircle">
        <v-progress-circular
            :rotate="360"
            :size="100"
            :width="15"
            :value="loadingValue"
            color="primary"
        >
          {{ loadingValue }}
        </v-progress-circular>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn depressed large
             color="primary"
             class="font-weight-bold action-button"
             @click="addNewKaadasPin"
             :disabled="!valid || loading">
        {{ $t('app.save') }}
      </v-btn>
    </template>

  </fullscreen-overlay-frame>
</template>

<script>

import FullscreenOverlayFrame from '@/templates/dialogs/FullscreenOverlayFrame'

export default {
  name: 'AddKaadasPinDialog',
  components: {
    FullscreenOverlayFrame,
  },
  props: ['data'],
  data: function () {
    return {
      saveCircle: false,
      loadingValue: 50,
      valid: false,
      pinName: '',
      pinValue: '',
      passwordVisible: false,
      loading: false,
      rule: {
        required: value => !!value || this.$t("add-kaadas-pin-dialog.hint.required"),
        minLength: value => value.length >= 6 || this.$t("add-kaadas-pin-dialog.hint.min-length"),
        maxLength: value => value.length <= 12 || this.$t("add-kaadas-pin-dialog.hint.max-length"),
        maxLengthName: value => value.length <= 20 || this.$t("add-kaadas-pin-dialog.hint.name-max-length"),
        number: value => {
          const pattern = /^[0-9]*$/
          return pattern.test(value) || this.$t("add-kaadas-pin-dialog.hint.numbers")
        },
        ascendingNumbers: value => !this.isSequence(value) || this.$t("add-kaadas-pin-dialog.hint.asc-sequence"),
        descendingNumbers: value => !this.isSequence(value, 'desc') || this.$t("add-kaadas-pin-dialog.hint.desc-sequence"),
        repeatedNumbers: value => !this.hasRepetition(value) || this.$t("add-kaadas-pin-dialog.hint.repetition")
      }
    }
  },

  methods: {
    /**
     * This function checks if the numbers are in an ascending or descending order.
     * @param value
     * @param direction
     * @returns {boolean}
     */
    isSequence(value, direction = 'asc') {
      const numbers = value.split('').map(Number)
      const step = direction === 'asc' ? 1 : -1

      return numbers.every((number, index) => {
        // skip the last number since there's no "next" number to compare
        if (index === numbers.length - 1) {
          return true
        }
        return numbers[index + 1] === number + step
      })
    },
    /**
     * This function checks for single number repetitions.
     * If the set has only one unique number, it's repeated.
     * @param value
     * @returns {boolean}
     */
    hasRepetition(value) {
      return new Set(value).size === 1
    },
    /**
     * closes AddKaadasPinDialog
     */
    abort() {
      this.$root.bisadialog.toggle('addKaadasPin')
    },
    addNewKaadasPin() {
      this.loading = true
      this.saveCircle = true
      this.loadingValue = 50
      this.$rhRequest.sendPost({
        endpoint: "kaadas/create-new-pin",
        timeout: "30000",
        data: {
          deviceId: this.data.device.id,
          name: this.pinName,
          lockPin: this.pinValue,
          permanent: this.data.isPermanent
        }
      }, () => {
        this.loading = false
        let emitTo = this.data.isPermanent ? 'Permanent' : 'Temp'
        emitTo = 'loadKaadasUser' + emitTo + this.data.device.id
        this.$root.bisatoast.success({message: this.$t('add-kaadas-pin-dialog.save.success-msg')})
        this.saveCircle = false
        this.$root.$emit(emitTo)
        this.abort()
      }, (e) => {
        let errMsg = e.response.data.code === -2 ? this.$t('add-kaadas-pin-dialog.save.duplicate-error-msg') : this.$t('app.generic-error')
        this.loading = false
        this.saveCircle = false
        this.$root.bisatoast.error({message: errMsg, showCloseBtn: true})
        console.error(e)
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import '~@/styles/dialogs/add-kaadas-pin';
</style>
