import moment from "moment";

export default {
    /**
     * returns a formatted date time string
     *
     * @param timestamp
     * @param format
     * @returns {string}
     */
    formatDate(timestamp, format) {
        let timeString = '-'
        if (timestamp) {
            timeString = moment.unix(timestamp).format(format)
        }
        return timeString
    },

    /**
     * returns a formatted date time string
     *
     * @param dateString string
     * @param format
     * @returns {string}
     */
    formatDateString(dateString, format) {
        let formattedString = '-'
        if (dateString) {
            formattedString = moment(dateString).format(format)
        }
        return formattedString
    },

    /**
     * converts a property value to a localized string representation, applies Wh to kWh conversion
     * for mec-meter total-consumption
     *
     * @param property
     * @returns {string}
     */
    localize(property) {
        if(property?.propertyId === '151') {
            return parseFloat(property.value / 1000).toLocaleString(undefined, {maximumFractionDigits: 2})
        } else if (typeof property?.value !== 'undefined' && property?.value !== null) {
            return parseFloat(property.value).toLocaleString(
              undefined, // use client locale
            )
        } else {
            return parseFloat(property).toLocaleString(
              undefined, // use client locale
            )
        }
    }
}
