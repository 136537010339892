<template>
  <fullscreen-overlay-frame :title="$t('invite-user-dialog.title').toString()"
                            icon="person_add"
                            color="primary"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>
      <v-progress-linear class="ma-auto" :value="progressValue"/>
      <v-stepper flat v-model="step" class="pt-1 transparent">
        <v-stepper-items>
          <!-- user email -->
          <v-stepper-content class="px-0" step="1">
            <div class="font-size-03 mb-6"
                 v-html="$t('invite-user-dialog.step1.description')"/>
            <v-form ref="form-email" v-model="emailValid">
              <v-text-field outlined
                            v-model="invitationData.email"
                            :rules="rules.email"
                            :label="$t('invite-user-dialog.email.label')"/>
            </v-form>
          </v-stepper-content>

          <!-- invite period restriction -->
          <v-stepper-content class="px-0" step="2">
            <div class="font-size-03 mb-6"
                 v-html="$t('invite-user-dialog.step2.description')"/>
            <v-form ref="form-restriction-period" v-model="periodRestrictionValid">
              <v-checkbox v-model="restrictPeriod"
                          :label="$t('invite-user-dialog.restrict-period.label')"/>
              <v-row class="mt-4">
                <v-col cols="12" sm="6">
                  <date-time-picker v-model="invitationData.valid_from"
                                    :label="$t('invite-user-dialog.valid-from.label').toString()"
                                    :disabled="!restrictPeriod"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <date-time-picker v-model="invitationData.valid_to"
                                    :label="$t('invite-user-dialog.valid-to.label').toString()"
                                    :rules="rules.periodRestriction"
                                    :disabled="!restrictPeriod"/>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <!-- device permissions -->
          <v-stepper-content class="px-0" step="3">
            <div class="font-size-03 mb-6"
                 v-html="$t('invite-user-dialog.step3.description')"/>

            <device-permissions-card v-model="devicePermissions"
                                     :devices="devices"
                                     :loading="loadingDevices"
                                     :compleo-connections="compleoConnections"/>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>

    <template v-slot:actions>
      <v-btn depressed large
             v-if="step < 3"
             color="primary"
             class="font-weight-bold action-button"
             @click="nextStep">
        {{ $t('app.continue') }}
      </v-btn>
      <v-btn depressed large
             v-else
             color="primary"
             class="font-weight-bold action-button"
             @click="submit">
        {{ $t('invite-user-dialog.submit') }}
      </v-btn>
      <v-btn depressed large
             v-if="step > 1"
             class="action-button mt-2"
             @click="step--"
             v-text="$t('app.go-back')"/>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import DateTimePicker from "@/templates/components/DateTimePicker";
import DevicePermissionsCard from "@/templates/components/profile/DevicePermissionsCard";
import moment from "moment-timezone";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: 'InviteUserDialog',

  components: {
    DevicePermissionsCard,
    DateTimePicker,
    FullscreenOverlayFrame
  },

  data: function () {
    return {
      items: [],
      step: 1,
      restrictPeriod: false,
      invitationData: {
        email: null,
        role: null,
        valid_from: null,
        valid_to: null,
        permission: [],
        device_selection: []
      },
      loadingDevices: false,
      devices: [],
      compleoConnections: [],
      devicePermissions: {
        devices: [],
        compleoConnection: false
      },
      emailValid: false,
      periodRestrictionValid: false,
      rules: {
        email: [
          v => !!v || this.$t('app.rules.required'),
          v => /.+@.+\..+/.test(v) || this.$t('app.rules.email-invalid')
        ],
        periodRestriction: [
          v => this.validatePeriod(v) || this.$t('app.rules.period-restriction-invalid')
        ]
      },
      weekdaysInit: [
        true,
        true,
        true,
        true,
        true,
        false,
        false
      ]
    }
  },

  computed: {
    progressValue() {
      return (this.step / 3) * 100
    }
  },

  methods: {
    /**
     * dialog initialization
     */
    init() {
      this.progressValue = 0
      this.step = 1
      this.restrictPeriod = false
      this.invitationData = {
        email: null,
        role: null,
        valid_from: null,
        valid_to: null,
        device_selection: []
      }
      this.devicePermissions = {
        devices: [],
        compleoConnection: false
      }
      this.$refs["form-email"].reset()
      this.$refs["form-restriction-period"].reset()
    },

    /**
     * executes form validation and conditionally increments the stepper value
     */
    nextStep() {
      switch (this.step) {
        case 1:
          if (this.$refs["form-email"].validate()) {
            this.step++
          }
          break
        case 2:
          if (this.$refs["form-restriction-period"].validate()) {
            this.step++
          }
          break
        default:
          this.step++
      }
    },

    /**
     * validates the user's period restriction input
     *
     * @param input
     * @returns {boolean}
     */
    validatePeriod(input) {
      if (this.restrictPeriod && this.invitationData.valid_from && input) {
        let parseFormat = 'YYYY-MM-DD HH:mm:ss'
        let validTo = moment.utc(input, parseFormat)
        if (validTo.isBefore(moment.utc(this.invitationData.valid_from, parseFormat))) {
          // end date must not be before start date
          return false
        }
      }
      return true
    },

    /**
     * gets all devices from the API which can be used for permission sharing
     */
    getWhitelistableDevices() {
      this.loadingDevices = true // the first response may set loading to false as there is now data to display
      // Nuki devices
      this.$rhRequest.sendGet({
        endpoint: 'settings/get-nuki-devices'
      }, (response) => {
        response?.data?.data?.forEach(device => {
          device['weekdays'] = Object.assign({}, this.weekdaysInit)
          this.devices.push(device)
        })
        this.loadingDevices = false
      }, (error) => {
        console.error(error)
        this.loadingDevices = false
      })

      // Kaadas locks
      this.$rhRequest.sendGet({
        endpoint: 'settings/get-kaadas-locks'
      }, (response) => {
        response?.data?.data?.forEach(device => {
          device['weekdays'] = Object.assign({}, this.weekdaysInit)
          this.devices.push(device)
        })
        this.loadingDevices = false
      }, (error) => {
        console.error(error)
        this.loadingDevices = false
      })

      // Wilka devices
      this.$rhRequest.sendGet({
        endpoint: 'wilka?filter[siteIds]=' + localStorage.activeSite + '&fields[lockCylinders]=name'
      }, (response) => {
        response?.data?.data?.forEach(device => {
          this.devices.push({
            device_key: device.id,
            name: device.attributes.name,
            selected: false,
            type: "Wilka Lock",
            weekdays: Object.assign({}, this.weekdaysInit)
          })
        })
        this.loadingDevices = false
      }, (error) => {
        console.error(error)
        this.loadingDevices = false
      })

      // Compleo
      this.$rhRequest.sendGet({
        endpoint: 'chargingstations/compleoConnections'
      }, (response) => {
        this.compleoConnections = response?.data?.data?.data ?? []
        this.compleoConnections.forEach(item => item.weekdays = Object.assign({}, this.weekdaysInit))
        this.loadingDevices = false
      }, (error) => {
        console.error(error)
        this.loadingDevices = false
      })
    },

    /**
     * submits a invitation
     */
    submit() {
      if (this.$refs["form-restriction-period"].validate()) {
        let data = this.invitationData
        data.role = "USER" // no user role selection is planned so far - add role selection if we plan to include multiple roles
        data.device_selection = this.devicePermissions.devices

        if (this.devicePermissions.compleoConnection) {
          data.compleo_permissions = Object.assign({}, this.devicePermissions.compleoRestriction)
          // remove id from permission obejct because otherwise backend will interpret it as device and not permissions for compleo connection
          delete data.compleo_permissions.id
          data.compleo_connections = this.compleoConnections
        }
        this.$rhRequest.sendPost({
          endpoint: 'settings/invite-user',
          data: data
        }, (response) => {
          let data = response?.data?.data
          if (!data?.success && data?.emailAlreadyInvited) {
            this.$root.bisatoast.warning({
              message: this.$t('invite-user-dialog.submit.already-invited'),
              showCloseBtn: true
            })
          } else if (data?.success && data?.emailAlreadyInvited) {
            this.$root.bisatoast.success({
              message: this.$t('invite-user-dialog.submit.reinvited'),
              showCloseBtn: true
            })
          } else if (data?.success && !data?.emailAlreadyInvited) {
            this.$root.bisatoast.success({
              message: this.$t('invite-user-dialog.submit.invited'),
              showCloseBtn: true
            })
          } else {
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
          }
          this.$root.bisadialog.toggle('inviteUser')
        }, (error) => {
          this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          console.error(error)
        })
      }
    },

    /**
     * closes the dialog without submitting any data
     */
    abort() {
      this.$root.bisadialog.toggle('inviteUser')
    }
  },

  mounted() {
    this.loadingDevices = true
    requestHelper.startDelayedRequest(() => this.getWhitelistableDevices())
  }
}

</script>

