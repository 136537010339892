<template>
  <fullscreen-overlay-frame :title="$t('energyline-data-support-dialog.title').toString()"
                            icon="support_agent"
                            color="primary"
                            centered
                            closable
                            @close="closeOverlay">

    <template v-slot:content>
      <div v-text="$t('energyline-data-support-dialog.info-text', {contractNumber: data?.contractNumber})"
           class="mb-8"/>

      <v-form v-model="valid" ref="form">
        <v-select outlined
                  v-model="subject"
                  :label="$t('energyline-data-support-dialog.subject')"
                  :items="Object.values(subjects)"
                  :rules="requiredRules"/>

        <v-alert v-if="subject === subjects[4]"
                 class="mb-5" type="info" text>
          <span v-html="$t('energyline-data-support-dialog.info-text.subjects.4')"/>
        </v-alert>

        <v-select outlined
                  v-if="showField('invoiceSubject')"
                  v-model="invoiceSubject"
                  :label="$t('energyline-data-support-dialog.invoice-subject')"
                  :items="Object.values(invoiceSubjects)"
                  :rules="requiredRules"/>

        <v-select outlined
                  v-if="showField('masterDataSubject')"
                  v-model="masterDataSubject"
                  :label="$t('energyline-data-support-dialog.master-data-subject')"
                  :items="Object.values(masterDataSubjects)"
                  :rules="requiredRules"/>

        <div v-if="masterDataSubject === masterDataSubjects[0]"
             class="mb-5"
             v-html="$t('energyline-data-support-dialog.info-text.master-data-subjects.0')"/>

        <v-alert v-if="showField('payment')"
                 class="mb-5" type="info" text>
          {{ $t('energyline-data-support-dialog.payment.hint') }}
        </v-alert>

        <v-text-field v-if="showField('payment')"
                      outlined
                      v-model="payment"
                      type="number" min="0" step="1"
                      :label="$t('energyline-data-support-dialog.payment')"
                      suffix="€"
                      :rules="requiredRules"
                      @keypress="avoidDecimals"/>

        <v-text-field v-if="showField('masterData')"
                      outlined
                      v-model="title"
                      :label="$t('energyline-data-support-dialog.master-data.title')"
                      :rules="requiredRules"/>

        <v-text-field v-if="showField('masterData')"
                      outlined
                      v-model="name"
                      :label="$t('energyline-data-support-dialog.master-data.name')"
                      :rules="requiredRules"/>

        <v-text-field v-if="showField('masterData')"
                      outlined
                      v-model="street"
                      :label="$t('energyline-data-support-dialog.master-data.street')"
                      :rules="requiredRules"/>

        <v-text-field v-if="showField('masterData')"
                      outlined
                      v-model="city"
                      :label="$t('energyline-data-support-dialog.master-data.city')"
                      :rules="requiredRules"/>

        <v-text-field v-if="showField('masterData')"
                      outlined
                      v-model="company"
                      :label="$t('energyline-data-support-dialog.master-data.company')"/>

        <v-text-field v-if="showField('nameChange')"
                      outlined
                      v-model="oldName"
                      :label="$t('energyline-data-support-dialog.old-name')"
                      :rules="requiredRules"/>

        <v-text-field v-if="showField('nameChange')"
                      outlined
                      v-model="newName"
                      :label="$t('energyline-data-support-dialog.new-name')"
                      :rules="requiredRules"/>

        <div v-if="showField('contactChange')"
             class="mb-5"
             v-html="$t('energyline-data-support-dialog.contact-change.hint')"/>

        <v-text-field v-if="showField('contactChange')"
                      outlined
                      v-model="phone"
                      :label="$t('energyline-data-support-dialog.phone')"
                      :rules="phoneChangeRules"
                      @change="$refs.form.resetValidation()"/>

        <v-text-field v-if="showField('contactChange')"
                      outlined
                      v-model="email"
                      :label="$t('energyline-data-support-dialog.email')"
                      :rules="emailChangeRules"
                      @change="$refs.form.resetValidation()"/>

        <v-menu v-if="showField('date')"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="formattedStartDate"
                          outlined
                          prepend-inner-icon="event"
                          :label="$t('energyline-data-support-dialog.date')"
                          readonly
                          v-bind="attrs"
                          v-on="on"/>
          </template>
          <v-date-picker v-model="date"
                         no-title
                         scrollable
                         :min="minDate()"
                         @input="dateMenu = false"/>
        </v-menu>

        <v-textarea v-if="showField('textArea')"
                    outlined
                    v-model="userMessage"
                    counter="2000"
                    height="250"
                    no-resize
                    :label="$t('form-dialog.textarea.label')"
                    :rules="messageRules"/>

        <div v-else-if="masterDataSubject === masterDataSubjects[2]"
             class="mb-5">
          {{ $t('energyline-data-support-dialog.info-text.master-data-subjects.2') }}
        </div>

        <v-file-input v-if="showField('file')"
                      outlined chips
                      if="fileInput"
                      v-model="file"
                      :label="$t('form-dialog.file-input.label')"
                      show-size
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      :rules="fileRules"
                      prepend-icon="">
          <template v-slot:append>
            <label for="#fileInput"> <!-- opens file selection when clicked -->
              <v-icon color="primary">attach_file</v-icon>
            </label>
            <v-btn v-if="data?.showCaptureButton"
                   icon small
                   class="ml-2"
                   @click="openCamera">
              <v-icon color="primary">photo_camera</v-icon>
            </v-btn>
          </template>
        </v-file-input>
      </v-form>

      <div v-text="$t('form-dialog.response-info', {sender: sender})"
           class="mt-4"/>
    </template>

    <template v-slot:actions>
      <v-btn large depressed
             color="primary"
             class="font-weight-bold action-button"
             :disabled="!valid"
             :loading="sending"
             @click="sendForm">
        {{ $t('app.send') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import formats from "@/scripts/formats";
import moment from "moment";
import config from "@/config/config.app.json";

export default {
  /* global Camera */ // resolve Cordova Camera Plugin
  name: 'EnergylineDataSupportDialog',

  components: {
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: function () {
    return {
      subjects: Object.freeze({
        0: "Vertragsanpassung",
        1: "Ändern der Kontodaten",
        2: "Ändern der Stammdaten",
        3: "Abschlagsanpassung",
        4: "Rechnungen",
        5: "Sonstiges"
      }),
      invoiceSubjects: Object.freeze({
        0: "Frage zur Rechnung",
        1: "Beschwerde zur Rechnung",
        2: "Rechnungskorrektur",
        3: "Rechnungserstellung",
        4: "Rechnung nicht erhalten",
        5: "Eigene Beschreibung",
      }),
      masterDataSubjects: Object.freeze({
        0: "Verwalterwechsel",
        1: "Abweichende Rechnungsanschrift",
        2: "Namensänderung",
        3: "Änderung der Kontaktinformationen"
      }),
      maxFileSize: 3000000, // 3 MB
      file: null,
      sending: false,
      userMessage: '',
      subject: null,
      invoiceSubject: "Frage zur Rechnung",
      masterDataSubject: null,
      valid: true,
      payment: null,
      dateMenu: false,
      date: null,
      title: null,
      name: null,
      street: null,
      city: null,
      company: null,
      oldName: null,
      newName: null,
      phone: null,
      email: null,
    }
  },

  methods: {
    /**
     * This method opens the camera to take picture
     */
    openCamera() {
      navigator.camera.getPicture(function (url) {
            this.getFileContentAsBase64(url)
          }, function (err) {
            console.error(err)
          },
          {
            // returns path to image
            destinationType: Camera.DestinationType.FILE_URI,
            // opens camera
            sourceType: Camera.PictureSourceType.CAMERA,
            // returns file of type jpeg
            encodingType: Camera.EncodingType.JPEG,
            // quality of image (100 would be full resolution)
            quality: 50
          });
    },

    /**
     * This method gets base64 content of a photo file. Takes url to that file as parameter
     *
     * @param url -> string
     */
    getFileContentAsBase64(url) {
      let self = this
      window.resolveLocalFileSystemURL(url, function success(fileEntry) {
        fileEntry.file(
            function success(file) {

              if (file.size > self.maxFileSize) {
                this.$root.bisatoast.error({message: self.$t('form-dialog.rules.file-too-large')})
              }

              let reader = new FileReader();
              reader.onload = function () {
              }
              reader.onloadend = function () {
                // create Blob from file because cordova-camera-plugin image-files wouldn't be sent
                self.createBlob(this.result, file.name)
              }
              reader.onerror = function (error) {
                console.error(error);
              }

              reader.readAsDataURL(file);

            }, function (error) {
              console.error(error)
            })
      }, function (error) {
        console.error(error)
      })
    },

    /**
     * This method takes base64 file and creates and returns blob
     *
     * @param base64File -> string
     * @param fileName
     */
    createBlob(base64File, fileName) {
      let block = base64File.split(";");
      let contentType = block[0].split(":")[1];
      let realData = block[1].split(",")[1];
      let blob = this.b64toBlob(realData, contentType);
      blob.name = fileName
      this.file = blob
    },

    /**
     * This method creates blob
     *
     * @param b64Data -> string
     * @param contentType -> string
     * @param sliceSize
     */
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {type: contentType});
    },

    /**
     * This method submits the form which was filled by the user to the API
     */
    sendForm() {
      this.sending = true

      let mailBody = this.$t('contract-selection-card.contract-number') + ":\n" + this.data?.contractNumber
          + "\n\n" + this.$t('contract-selection-card.customer-id') + ":\n" + this.data?.customerId
          + "\n\n" + this.$t('energyline-data-support-dialog.client-key') + ":\n" + this.$t('energyline-data-support-dialog.mapped-client-key')
      if (this.showField('invoiceSubject')) {
        mailBody += "\n\n" + this.$t('energyline-data-support-dialog.invoice-subject') + ":\n" + this.invoiceSubject
      } else if (this.showField('masterDataSubject')) {
        mailBody += "\n\n" + this.$t('energyline-data-support-dialog.master-data-subject') + ":\n" + this.masterDataSubject
      }
      if (this.showField('payment')) {
        mailBody += "\n\n" + this.$t('energyline-data-support-dialog.payment') + ":\n" + this.payment
      }
      if (this.showField('masterData')) {
        mailBody += "\n\n" + this.$t('energyline-data-support-dialog.master-data.title') + ":\n" + this.title
            + "\n\n" + this.$t('energyline-data-support-dialog.master-data.name') + ":\n" + this.name
            + "\n\n" + this.$t('energyline-data-support-dialog.master-data.street') + ":\n" + this.street
            + "\n\n" + this.$t('energyline-data-support-dialog.master-data.city') + ":\n" + this.city
            + "\n\n" + this.$t('energyline-data-support-dialog.master-data.company') + ":\n" + this.company
      }
      if (this.showField('nameChange')) {
        mailBody += "\n\n" + this.$t('energyline-data-support-dialog.old-name') + ": " + this.oldName + "\n" + this.$t('energyline-data-support-dialog.new-name') + ": " + this.newName
      }
      if (this.showField('contactChange')) {
        mailBody += "\n\n" + this.$t('energyline-data-support-dialog.email') + ": " + this.email + "\n" + this.$t('energyline-data-support-dialog.phone') + ": " + this.phone
      }
      if (this.showField('date')) {
        mailBody += "\n\n" + this.$t('energyline-data-support-dialog.date') + ":\n" + this.formattedStartDate
      }
      if (this.showField('textArea')) {
        mailBody += "\n\n" + this.$t('energyline-data-support-dialog.user-message') + ":\n" + this.userMessage
      }

      let formData = new FormData();
      if (this.file) {
        formData.append('attachment', this.file, this.file.name)
      }
      formData.append('recipientAddress', config.superofficeRecipient)
      formData.append('replyTo', this.sender)
      formData.append('senderName', '')
      formData.append('subject', this.subject)
      formData.append('body', mailBody)
      formData.append('noSignature', true)

      this.$rhRequest.sendPost({
        endpoint: 'mail/send',
        serializer: 'multipart',
        header: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }, () => {
        // send copy to user
        this.sendCopy(mailBody);
      }, (error) => {
        this.sending = false
        console.error(error)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
      })
    },

    /**
     * sends a copy to the user
     */
    sendCopy(mailBody) {
      let formData = new FormData();
      if (this.file) {
        formData.append('attachment', this.file, this.file.name)
      }
      formData.append('body', mailBody)
      formData.append('noSignature', true)
      formData.append('recipientAddress', this.sender)
      formData.append('subject', this.$t("form-section.subject.copy", {subject: this.subject}).toString())
      formData.append('noSignature', true)

      this.$rhRequest.sendPost({
        endpoint: 'mail/send',
        serializer: 'multipart',
        header: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }, () => {
        this.sending = false
        this.closeOverlay()
        this.$root.bisatoast.success({message: this.$t('form-dialog.send.success'), showCloseBtn: true})
      }, (error) => {
        this.sending = false
        console.error(error)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
      })
    },

    /**
     * returns, if an input field is to be shown for the selected subject
     * @param fieldName
     */
    showField(fieldName) {
      switch (fieldName) {
        case 'payment':
          return this.subject === this.subjects[3]
        case 'date':
          return this.subject === this.subjects[3]
              || this.masterDataSubject === this.masterDataSubjects[0]
              || this.masterDataSubject === this.masterDataSubjects[1]
              || this.masterDataSubject === this.masterDataSubjects[2]
              || this.masterDataSubject === this.masterDataSubjects[3]
        case 'invoiceSubject':
          return this.subject === this.subjects[4]
        case 'masterDataSubject':
          return this.subject === this.subjects[2]
        case 'masterData':
          return this.masterDataSubject === this.masterDataSubjects[1]
        case 'nameChange':
          return this.masterDataSubject === this.masterDataSubjects[2]
        case 'contactChange':
          return this.masterDataSubject === this.masterDataSubjects[3]
        case 'textArea':
          return this.subject === this.subjects[4]
              || this.subject === this.subjects[0]
              || this.subject === this.subjects[1]
              || this.subject === this.subjects[5]
        case 'file':
          return this.subject === this.subjects[4]
              || this.masterDataSubject === this.masterDataSubjects[0]
              || this.masterDataSubject === this.masterDataSubjects[1]
              || this.masterDataSubject === this.masterDataSubjects[2]
              || this.masterDataSubject === this.masterDataSubjects[3]
              || this.subject === this.subjects[0]
              || this.subject === this.subjects[1]
              || this.subject === this.subjects[5]
      }
    },

    minDate() {
      let date
      if (moment().date() < 15) {
        date = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')
      } else {
        date = moment().add(2, 'M').startOf('month').format('YYYY-MM-DD');
      }
      if (!this.date) {
        this.date = date
      }
      return date
    },

    avoidDecimals($event) {
      if (!($event.charCode >= 48 && $event.charCode <= 57)) {
        $event.preventDefault()
      }
    },

    /**
     * on dialog close
     */
    closeOverlay() {
      this.userMessage = ''
      this.$root.bisadialog.toggle('energylineDataSupport')
    }
  },

  computed: {
    requiredRules() {
      return [
        v => !!v || this.$t('app.rules.required')
      ]
    },

    phoneChangeRules() {
      console.log(this.email)
      return [
        v => (!!v || !!this.email) || this.$t('app.rules.required')
      ]
    },

    emailChangeRules() {
      console.log(this.phone)
      return [
        v => (!!v || !!this.phone) || this.$t('app.rules.required')
      ]
    },

    fileRules() {
      return [
        v => (v && this.masterDataSubject === this.masterDataSubjects[0])
            || (v && this.masterDataSubject === this.masterDataSubjects[2])
            || (this.masterDataSubject !== this.masterDataSubjects[0] && this.masterDataSubject !== this.masterDataSubjects[2])
            || this.$t('app.rules.required'),
        value => !value || value.size < this.maxFileSize || this.$t('form-dialog.rules.file-too-large'),
      ]
    },

    messageRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => (v && v.length <= 2000) || this.$t('app.rules.too-long', {maxLength: 2000})
      ]
    },

    sender() {
      return localStorage.getItem('user')
    },

    /**
     * returns localized startDate string
     * @returns {null|string}
     */
    formattedStartDate() {
      if (this.date) {
        return formats.formatDateString(this.date, this.$t('app.date-time-format.date-only'))
      }
      return null
    },
  },

  watch: {
    subject() {
      this.$refs.form.resetValidation()
    },

    masterDataSubject() {
      this.$refs.form.resetValidation()
    }
  }
};
</script>

