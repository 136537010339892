/* eslint-disable */
import Vue from 'vue'

export default class {

  constructor() {
  }

  /**
   * call this method to perform a user login
   *
   * @param email
   * @param pass
   * @param cb
   */
  login(email, pass, cb) {
    if (localStorage.token) {
      if (cb) {
        // execute callback code
        cb({authenticated: true})
      }
      return
    }

    this.sendLoginRequest(email, pass, (res) => {
      if (res.authenticated) {
        localStorage.user = email
        localStorage.token = res.token
        localStorage.role = res.role
        localStorage.permissionsId = res.permissionsId
        this.storeActiveSite()
        if (cb) {
          // execute callback code
          cb({authenticated: true})
        }
      } else if (res.timeout) {
        cb({authenticated: false, timeout: true})
      } else {
        if (cb) {
          // execute callback code, return data which is se tin sendLoginRequest callback
          cb(res)
        }
      }
    })
  }

  logout(cb) {
    delete localStorage.user
    delete localStorage.token
    delete localStorage.role
    delete localStorage.permissionsId
    delete localStorage.activeSite
    if(window.usingCordova){
      Vue.prototype.$rhPushNotification.unsubscribeAllTopics()
      Vue.prototype.$rhPushNotification.unregisterFromFirebase()
    }
    Vue.prototype.$caching.deleteDatabase()
    if (cb) cb()
  }

  loggedIn() {
    return !!localStorage.token && (!!localStorage.permissionsId && (!localStorage.permissionsId <= 40 || !localStorage.permissionsId >= 85))
  }

  /**
   * sends a login request to the portal API
   *
   * @param email
   * @param password
   * @param cb
   */
  sendLoginRequest(email, password, cb) {
    let options = {
      endpoint: 'user/login',
      serializer: 'urlencoded',
      logout: false,
      data: {
        username: email,
        password: password
      }
    }

    Vue.prototype.$rhRequest.sendPost(options, function (response) {
      if (response?.data?.data?.permissionsId <= 40 || response?.data?.data?.permissionsId >= 85) {
        cb({authenticated: false})
      } else {
        cb({
          authenticated: true,
          token: response.headers.authtoken ? response.headers.authtoken : Math.random().toString(36).substring(7),
          role: response?.data?.data?.role,
          permissionsId: response?.data?.data?.permissionsId
        })
      }

    }, function (e) {
      console.error(e)
      cb({authenticated: false, timeout: e.code === 'ECONNABORTED', errorCode: e?.response?.data?.code})
    })
  }

  /**
   * writes the currently active site of the logged-in user into the local storage
   */
  storeActiveSite() {
    let options = {
      endpoint: 'multisite/get-selected-site',
    }

    Vue.prototype.$rhRequest.sendGet(options, function (response) {
      localStorage.setItem('activeSite', response?.data?.data?.siteId)
    }, function (e) {
      console.error("Could not load active site for user: " + e)
      this.$root.bisatoast.error({
        message: this.$t('auth.get-active-site.error'),
        showCloseBtn: true
      })
    })
  }

  /**
   * returns true if the role of the logged in user is 'site admin'
   * @returns {boolean}
   */
  isSiteAdmin() {
    return localStorage.role === 'ADMIN'
  }
}
