<template>
  <v-skeleton-loader v-if="loading"
                     type="article"
                     height="300"/>

  <content-card v-else
                :title="$t('rules-card.title').toString()"
                icon="auto_awesome">
    <template v-slot:content>
      <v-list subheader class="py-0 tileBackground">

        <!-- empty state -->
        <v-list-item v-if="rules?.length === 0"
                     class="grey--text">
          <div class="ma-8 mb-4 text-center"
               style="width: 100%"
               v-text="$t('rules-card.no-data')"/>
        </v-list-item>

        <!-- rules list -->
        <v-list-item v-else
                     v-for="rule in rules"
                     :key="rule.attributes.id"
                     class="list-item straight px-5"
                     @click="editRule(rule)">
          <v-list-item-icon class="mr-2">
            <span v-if="rule.attributes.iconKey" class="material-symbols-outlined v-icon ma-0"
                  :class="[(!rule.attributes.deactivated ? 'primary--text' :''), ($vuetify.theme.isDark ? 'theme--dark': 'theme--light')]">{{ rule.attributes.iconKey }}</span>
            <v-icon v-else class="material-icons-outlined"
                    :color="rule.attributes.deactivated ? '' : 'primary'">
              auto_awesome
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-size-03"
                               :class="rule.attributes?.deactivated ? '' : 'primary--text font-weight-bold'">
              {{ rule.attributes.name }}
            </v-list-item-title>

            <v-list-item-subtitle v-if="rule.attributes.condition"
                                  class="font-size-02">
              <condition-summary :condition="rule.attributes.condition"/>
            </v-list-item-subtitle>
            <!-- add cases for other condition types here as soon as we support them -->
          </v-list-item-content>

          <v-list-item-action class="align-center">
            <v-btn icon
                   class="mr-1"
                   @click.stop="editRule(rule)">
              <v-icon v-if="isSiteAdmin"
                      color="primary"
                      class="material-icons-outlined">
                edit
              </v-icon>
              <v-icon v-else
                      color="primary"
                      class="material-icons-outlined">
                visibility
              </v-icon>
            </v-btn>

            <!-- sync status -->
            <v-tooltip bottom
                       v-if="rule.attributes.synced === 'FAILED'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="material-icons-outlined mr-2 mb-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on">
                  sync_problem
                </v-icon>
              </template>
              <span v-html="$t('rules-card.sync-error')"/>
            </v-tooltip>
            <v-tooltip bottom
                       v-else-if="rule.attributes.synced === 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="material-icons-outlined mr-2 mb-1 spin"
                        color="info"
                        v-bind="attrs"
                        v-on="on">
                  sync
                </v-icon>
              </template>
              <span v-html="$t('rules-card.sync-running')"/>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";
import formats from "@/scripts/formats";
import ConditionSummary from "@/templates/components/living/rules/ConditionSummary.vue";
import {conditionTypes} from "@/scripts/automations";

export default {
  name: "RulesCard",

  components: {ConditionSummary, ContentCard},

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      loading: false,
      rules: []
    }
  },

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  },

  methods: {
    /**
     * gets scenes from the API
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'rule/get'
      }, (response) => {
        let rules = response?.data?.data
        if (rules == null) {
          this.loading = false
          return
        }

        // get device ids for condition of type 'properties' - we will need them to request the device data as condition metaData
        let conditionDeviceIds = []
        if (rules.length > 0) {
          rules.forEach(rule => {
            if (rule.attributes.condition?.type === conditionTypes.properties) {
              conditionDeviceIds.push(rule.attributes.condition.deviceId)
            }
          })
        }

        // add devices to condition metaData if at least one condition is of type 'properties'
        if (conditionDeviceIds.length > 0) {
          this.addMetaData(rules, conditionDeviceIds)
        } else {
          this.rules = rules
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    /**
     * adds device name to all rule conditions with device ids
     *
     * @param rules
     * @param deviceIds
     */
    addMetaData(rules, deviceIds) {
      this.$rhRequest.sendGet({
        endpoint: "devices/get",
        params: {
          deviceIds: deviceIds.join(',')
        }
      }, (resp) => {
        let deviceList = Object.values(resp?.data?.data)
        rules?.forEach(rule => {
          rule.attributes.condition.metaData = {
            device: deviceList.find(device => device.id === rule.attributes.condition.deviceId)
          }
        })
        this.rules = rules
        this.loading = false
      }, (err) => {
        console.error(err)
        this.loading = false
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },

    /**
     * edits a rule
     * @param rule
     */
    editRule(rule) {
      this.$root.bisadialog.toggle('addRule', true, {rule: rule})
      this.$root.bisadialog.callDialogInit('addRule')
    },

    /**
     * returns a formatted string representation for date
     * @param date
     * @returns {string}
     */
    formatDate(date) {
      return formats.formatDateString(date, this.$t('app.date-time-format.date-only'))
    },
    loadCachedData() {
      this.$caching.getData('rules').then((rules) => {
        if (rules !== -1) {
          this.rules = rules
          this.loading = false
        }
      })
    }
  },

  mounted() {
    this.loading = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
        () => this.getData(false),
        () => this.updateData()
    )
    this.$root.$on('reloadItems', () => {
      this.getData(false)
    })
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    rules(newVal) {
      this.$caching.saveData('rules', newVal)
    }
  }
}
</script>
