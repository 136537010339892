<template>
  <v-img height="200" v-if="true"
         :src="headerImgSrc">
    <div class="header-wrapper">

      <!-- gateway online status -->
      <div class="pl-6 pt-9 header white--text">
        <div class="header-icon">
          <v-progress-circular v-if="loading.gateway" color="primary"
                               size="24" width="2" indeterminate/>
          <v-icon v-else-if="!gatewayAdded">
            portable_wifi_off
          </v-icon>
          <v-icon v-else-if="online" color="success">
            podcasts
          </v-icon>
          <v-icon v-else color="error">
            wifi_tethering_error
          </v-icon>
        </div>
        <div class="header-text d-inline-block ml-4">
          <v-skeleton-loader v-if="loading.gateway"
                             max-width="300"
                             class="mb-2"
                             type="heading"/>
          <div v-else-if="!gatewayAdded" class="font-weight-bold font-size-07" v-html="$t('gateway.status.not-added')"/>
          <div v-else-if="online" class="font-weight-bold font-size-07" v-html="$t('gateway.status.online')"/>
          <div v-else-if="timeout" class="font-weight-bold font-size-07" v-html="$t('gateway.status.timeout')"/>
          <div v-else class="font-weight-bold font-size-07" v-html="$t('gateway.status.offline')"/>

          <div v-if="gatewayAdded && !!mac" class="font-size-03"
               v-html="$t('gateway-status-card.mac', {mac: mac})"/>
        </div>

        <!-- controller mode -->
        <div v-if="online"
             class="float-sm-right mr-5 ml-16 my-2">
          <v-skeleton-loader v-if="loading.mode" type="button"/>
          <v-chip v-else-if="modes[mode]" :color="modes[mode].color" class="inputBackground--text" label
                  @click="showModeHelp(mode)">
            <v-progress-circular v-if="modes[mode].animation"
                                 class="mr-2" size="16" width="2" indeterminate/>
            <span v-html="modes[mode].label"/>
            <v-icon right>chevron_right</v-icon>
          </v-chip>
          <v-chip v-else label
                  v-html="$t('gateway.mode.unknown.label')"/>
        </div>

        <!-- gateway offline help -->
        <v-btn v-else-if="gatewayAdded && !loading.gateway"
               depressed class="float-md-right mr-5 ml-16 my-2"
               color="primary"
               @click="showHelp">
          <v-icon left class="non-flip">help</v-icon>
          {{ $t('gateway-status-card.help-btn') }}
        </v-btn>
      </div>
    </div>
  </v-img>
</template>

<script>

import theme from "@/config/theme";
import requestHelper from "@/scripts/requestHelper";
import config from "@/config/config.app.json";

export default {
  name: "SmarthomeHeader",

  props: ['gatewayAdded'],

  data() {
    return {
      loading: {
        gateway: false,
        mode: true
      },
      online: null,
      timeout: false,
      mac: '',
      mode: null,
      modes: Object.freeze({
        NORMAL: {
          label: this.$t('gateway.mode.normal.label'),
          description: this.$t('gateway.mode.normal.description'),
          color: 'success',
          animation: false
        },
        ADDING: {
          label: this.$t('gateway.mode.adding.label'),
          description: this.$t('gateway.mode.adding.description'),
          color: 'success',
          animation: true
        },
        REMOVING: {
          label: this.$t('gateway.mode.removing.label'),
          description: this.$t('gateway.mode.removing.description'),
          color: 'success',
          animation: true
        },
        PROCESS_CONTROL_EVENT: {
          label: this.$t('gateway.mode.process-control-events.label'),
          description: this.$t('gateway.mode.process-control-events.description'),
          color: 'warning',
          animation: true
        },
      })
    }
  },

  computed: {

    /**
     * Allows to override the header logo src in theme.js
     *
     * @returns {*}
     */
    headerImgSrc() {
      return theme.props?.headerLogoSrc
    },
  },

  methods: {
    /**
     * gets gateway data for the currently active site
     */
    getGateway(showLoader) {
      if (showLoader) {
        this.loading.gateway = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'gateway'
      }, (response) => {
        this.timeout = false
        this.online = response?.data?.data[0]?.online
        this.mac = response?.data?.data[0]?.model
        this.$emit('mac', this.mac)
        this.loading.gateway = false
        if (this.online) {
          this.getMode()
        }
      }, (error) => {
        if (error.code === 'ECONNABORTED') {
          this.timeout = true
        }
        console.error(error)
        this.loading.gateway = false
      })
    },


    /**
     * continuously updates the data
     */
    updateData() {
      let interval = config.updateInterval
      if (this.mode !== 'NORMAL') {
        interval /= 2
      }
      this.timer = setInterval(() => {
        this.getGateway(false)
      }, interval)
    },

    getMode() {
      this.$rhRequest.sendGet({
        endpoint: 'gateway/get-mode'
      }, (response) => {
        this.loading.mode = false
        this.mode = response?.data?.data?.data?.mode
      }, (error) => {
        console.error(error)
        this.loading.mode = false
      })
    },

    /**
     * opens a help dialog
     */
    showModeHelp(mode) {
      this.$root.bisadialog.toggle('infoText', true, {
        title: this.modes[mode].label,
        icon: 'info',
        text: this.modes[mode].description
      })
    },

    /**
     * opens a help dialog
     */
    showHelp() {
      this.$root.bisadialog.toggle('gatewayOfflineHelp')
    },

    loadCachedData() {
      Promise.all([
        this.$caching.getData('mac').then((mac) => {
          if (mac !== -1) {
            this.mac = mac
          }
        }),
        this.$caching.getData('gatewayOnline').then((online) => {
          if (online !== -1) {
            this.online = online
          }
        })
      ]).then(() => {
        this.loading.gateway = false
      })
    }
  },

  mounted() {
    this.loading.gateway = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
        () => this.getGateway(false),
        () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },

  watch: {
    mac(newVal) {
      this.$caching.saveData('mac', newVal)
    },

    online(newVal) {
      this.$caching.saveData('gatewayOnline', newVal)
    }
  }
}
</script>
