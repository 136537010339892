<template>
  <v-skeleton-loader v-if="loading"
                     type="article"
                     height="300"/>
  <content-card v-else-if="Object.values(legacyScenes)?.length > 0 || (scenes != null && Object.values(scenes)?.length > 0)"
                :title="$t('scenes-card.title').toString()"
                icon="play_circle">
    <template v-slot:content>
      <v-list subheader class="py-0 tileBackground">
        <!-- scene overview -->
        <v-list-item v-for="scene in scenes"
                     :key="scene.id"
                     class="list-item straight px-5"
                     @click="editScene(scene)">
          <v-list-item-icon class="mr-2">
            <!-- TODO that material symbols stuff probably needs to be refactored -->
            <span v-if="scene.attributes.iconKey"
                  class="material-symbols-outlined v-icon ma-0"
                  :class="[(!scene.attributes.deactivated ? 'primary--text' :''), ($vuetify.theme.isDark ? 'theme--dark': 'theme--light')]">
              {{ scene.attributes.iconKey }}
            </span>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                               v-text="scene.attributes.name"/>
            <v-list-item-subtitle class="font-size-02">
              <span>{{ $t("scenes-card.subtitle.actions-count", {n: scene.attributes.actions.length}) }} </span>
              <span v-if="scene.attributes.delay">{{ $t("scenes-card.subtitle.delay", {delay: scene.attributes.delay}) }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="align-center">
            <!-- edit button -->
            <v-btn icon
                   @click.stop="editScene(scene)">
              <v-icon v-if="isSiteAdmin"
                      color="primary"
                      class="material-icons-outlined">
                edit
              </v-icon>
              <v-icon v-else
                      color="primary"
                      class="material-icons-outlined">
                visibility
              </v-icon>
            </v-btn>

            <!-- play button -->
            <v-btn x-small fab depressed
                   color="primary"
                   class="mr-1"
                   @click.native.stop="play(scene)">
              <v-icon v-if="scene.attributes.delay === null">play_arrow</v-icon>
              <v-icon v-else>timer</v-icon>
            </v-btn>

            <!-- sync status -->
            <v-tooltip bottom
                       v-if="scene.attributes.synced === 'FAILED'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="material-icons-outlined mr-2 mb-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on">
                  sync_problem
                </v-icon>
              </template>
              <span v-html="$t('rules-card.sync-error')"/>
            </v-tooltip>
            <v-tooltip bottom
                       v-else-if="scene.attributes.synced === 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="material-icons-outlined mr-2 mb-1 spin"
                        color="info"
                        v-bind="attrs"
                        v-on="on">
                  sync
                </v-icon>
              </template>
              <span v-html="$t('rules-card.sync-running')"/>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>

        <!-- legacy scenes -->
        <v-list-item v-for="scene in legacyScenes"
                     :key="scene.id"
                     class="list-item straight px-5">
          <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                             v-text="scene.name"/>
          <v-list-item-action>
            <v-btn icon
                   @click="deleteScene(scene)">
              <v-icon>delete</v-icon>
            </v-btn>

            <v-btn x-small fab depressed
                   color="primary"
                   class="mr-1"
                   @click.native.stop="legacyPlay(scene)">
              <v-icon v-if="scene.delay === 0">play_arrow</v-icon>
              <v-icon v-else>timer</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "ScenesCard",

  components: {ContentCard},

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      loading: false,
      scenes: [],
      legacyScenes: []
    }
  },

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  },

  methods: {
    /**
     * get scenes from the API
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'scenes/get'
      }, (response) => {
        if (response?.data?.code === -1) {
          this.getLegacyData(false)
          return
        }
        this.scenes = response?.data?.data
        if (this.scenes === null || typeof this.scenes === 'undefined') {
          this.getLegacyData(false)
          return
        }
        this.scenes.map((scene) => {
          Object.keys(scene.attributes.actions).map((key) => {
            if (scene.attributes.actions[key].type === 'sceneRefs') {
              scene.attributes.actions[key].id = Math.random().toString(36)
            }
          })

        })

        this.getLegacyData(false)
        this.loading = false
      }, (error) => {
        console.error(error)
        this.getLegacyData(false)
      })
    },

    /**
     * get legacy scenes from the API
     */
    getLegacyData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'scene'
      }, (response) => {
        this.legacyScenes = response?.data?.data
        let hasNoLegacyScenes;
        if (this.legacyScenes === null || typeof this.legacyScenes === 'undefined') {
          hasNoLegacyScenes = true;
        } else {
          hasNoLegacyScenes = Object.values(this.legacyScenes)?.length === 0;
        }

        let hasNoScenes = false;
        if (this.scenes !== null && typeof this.scenes !== 'undefined') {
          hasNoScenes = Object.values(this.scenes)?.length === 0;
        }

        if (hasNoLegacyScenes && hasNoScenes) {
          this.$emit('hide-scenes-card', true)
        } else {
          this.$emit('hide-scenes-card', false)
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        if (Object.values(this.scenes)?.length === 0) {
          this.$emit('hide-scenes-card', true)
        }
        this.loading = false
      })
    },

    /**
     * plays a scene
     * @param scene
     */
    play(scene) {
      this.$rhRequest.sendGet({
        endpoint: 'scenes/play',
        params: {id: scene.id}
      }, (response) => {
        if (response.data.code === 0 && scene.delay > 0) {
          this.$root.bisatoast.success({
            message: this.$t('scenes-card.play-delayed.success', {name: scene.attributes.name, delay: scene.attributes.delay}),
            showCloseBtn: true
          })
        } else if (response.data.code === 0) {
          this.$root.bisatoast.success({
            message: this.$t('scenes-card.play.success', {name: scene.attributes.name}),
            showCloseBtn: true
          })
        } else {
          this.$root.bisatoast.error({
            message: this.$t('scenes-card.play.failed', {name: scene.attributes.name}),
            showCloseBtn: true
          })
        }
      }, (error) => {
        console.error(error)
        if (error.response.data.code === 580) {
          this.$root.bisatoast.warning({message: this.$t('scenes-card.play.gateway-offline'), showCloseBtn: true})
        } else {
          this.$root.bisatoast.error({
            message: this.$t('scenes-card.play.error'),
            showCloseBtn: true
          })
        }
      })
    },

    /**
     * plays a legacy scene
     *
     * @param scene
     */
    legacyPlay(scene) {
      this.$rhRequest.sendGet({
        endpoint: 'scene/' + scene.id + "/play"
      }, (response) => {
        if (response.data.code === 0 && scene.delay > 0) {
          this.$root.bisatoast.success({
            message: this.$t('scenes-card.play-delayed.success', {name: scene.name, delay: scene.delay}),
            showCloseBtn: true
          })
        } else if (response.data.code === 0) {
          this.$root.bisatoast.success({
            message: this.$t('scenes-card.play.success', {name: scene.name}),
            showCloseBtn: true
          })
        } else {
          this.$root.bisatoast.error({
            message: this.$t('scenes-card.play.failed', {name: scene.name}),
            showCloseBtn: true
          })
        }
      }, (error) => {
        this.$root.bisatoast.error({
          message: this.$t('scenes-card.play.error'),
          showCloseBtn: true
        })
        console.error(error)
      })
    },

    /**
     * edits a scene
     * @param scene
     */
    editScene(scene) {
      this.$root.bisadialog.toggle('addScene', true, {scene: scene})
      this.$root.bisadialog.callDialogInit('addScene')
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
        this.getLegacyData(false)
      }, this.refreshRate)
    },

    /**
     * Deletes a scene.
     * @param scene
     */
    deleteScene(scene) {
      if (!this.loading) {
        this.$root.bisadialog.toggle('confirmation', true, {
          maxWidth: 400,
          title: this.$t('confirmation-dialog.title'),
          text: this.$t('add-scene-dialog.delete-scene.confirm', {name: scene.name}),
          confirmLabel: this.$t('add-scene-dialog.delete')
        })
        this.$root.$on('dialogConfirmed', () => {
          this.$rhRequest.sendDelete({
            endpoint: 'scene/delete/' + scene.id
          }, () => {
            this.$root.bisatoast.success({
              message: this.$t('add-scene-dialog.delete-rule.success', {name: scene.name}),
              showCloseBtn: true
            })
            this.getData(false)
          }, (error) => {
            console.error(error)
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          })
          this.$root.$off('dialogConfirmed')
        })
      }
    },
    loadCachedData() {
      Promise.all([
          this.$caching.getData('scenes').then((scenes) => {
            if (scenes !== -1) {
              if (!scenes) {
                this.scenes = []
              } else {
                this.scenes = scenes
              }
            }
          }),
          this.$caching.getData('legacyScenes').then((legacyScenes) => {
            if (legacyScenes!== -1) {
              this.legacyScenes = legacyScenes
              if (!legacyScenes) {
                this.legacyScenes = []
              } else {
                this.legacyScenes = legacyScenes
              }
            }
          })
      ]).then(() => {
        if ((this.scenes && this.scenes.length === 0) || (this.legacyScenes && this.legacyScenes.length === 0)) {
          this.$emit('hide-scenes-card', true)
        } else {
          this.$emit('hide-scenes-card', false)
        }
      })
    }
  },

  mounted() {
    this.loadCachedData()
    requestHelper.startDelayedRequest(
        () => {
          this.getData(false)
        },
        () => this.updateData()
    )
    this.$root.$on('reloadItems', () => {
      this.getData(false)
    })
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    scenes(newVal) {
      this.$caching.saveData('scenes', newVal)
    },
    legacyScenes(newVal) {
      this.$caching.saveData('legacyScenes', newVal)
    }
  }
}
</script>
