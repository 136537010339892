<template>
  <div>
    <v-skeleton-loader v-if="isLoading()"
                       type="table"/>

    <content-card v-else
                  color="primary"
                  :title="$t('accesses-card.title')"
                  icon="key"
                  v-show="devices.length > 0">

      <template v-slot:content>
        <v-data-table :headers="headers"
                      :items="devices"
                      item-key="id"
                      class="row-pointer tileBackground"
                      mobile-breakpoint="0"
                      :items-per-page="-1"
                      hide-default-header
                      hide-default-footer
                      @click:row="openLockDevice">

          <template v-slot:[`item.name`]="{ item }">
            <v-list-item class="pa-1">
              <v-list-item-icon class="mr-2">
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="device-title">
                <v-list-item-title class="primary--text font-weight-bold font-size-03">
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-size-02" v-text="item.type"/>
              </v-list-item-content>
            </v-list-item>

          </template>

          <template v-slot:[`item.batteryLevel`]="{ item }">
            <span v-if="item.protocol == 'wilka'">
              <v-icon v-if="item.batteryLevel === 0" color="error">battery_alert</v-icon>
              <v-icon v-else-if="item.batteryLevel === 1" color="warning">battery_2_bar</v-icon>
              <v-icon v-else-if="item.batteryLevel === 5" color="primary">battery_full</v-icon>
              <v-icon v-else-if="item.batteryLevel > 0" color="primary">{{ "battery_" + (item.batteryLevel + 2) + "_bar" }}</v-icon>
            </span>
          </template>

          <!-- chevron -->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.chevron="{ item }">
            <v-icon color="primary">chevron_right</v-icon>
          </template>
        </v-data-table>
      </template>
    </content-card>

  </div>

</template>

<script>
import ContentCard from "@/templates/components/ContentCard.vue";
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";


export default {
  name: "LockDeviceList",
  components: {ContentCard},
  data() {
    return {
      loading: {
        devices: {
          wilka: false,
          other: false
        }
      },
      timeout: false,
      headers: [
        {
          align: 'start',
          value: 'name',
        },
        {
          align: 'center',
          value: 'batteryLevel',
          cellClass: 'width-20'
        },
        {
          value: 'chevron',
          align: 'right',
          cellClass: 'pl-0 width-50'
        }
      ],
      devices: []
    }
  },
  methods: {
    getWilkaDevices() {
      this.$rhRequest.sendGet({
            endpoint: 'wilka?filter[siteIds]=' + localStorage.activeSite + '&fields[lockCylinders]=name,batteryLevel,bluetoothId,ownership',
          },
          (r) => {
            let batteryLevels = this.mapBatteryLevels(r?.data?.included)
            let devices = JSON.parse(JSON.stringify(this.devices))

            r?.data?.data?.forEach((e) => {
              let device = {
                icon: 'lock',
                id: e.id,
                name: e.attributes.name,
                bluetoothId: e.attributes.bluetoothId,
                batteryLevel: batteryLevels.get(e.relationships?.batteryLevel?.data?.id),
                ownership: e.attributes.ownership,
                protocol: 'wilka',
                type: this.$t(`wilka.${e.type.replace(/(.*)s$/,'$1')}.type.label`)
              }

              let index = devices.findIndex(e => e.bluetoothId === device.bluetoothId)
              if (index != -1) {
                devices[index] = device
              } else {
                devices.push(device)
              }
            })
            this.devices = devices

            if (devices?.length > 0) {
              this.$emit('hide-lock-devices-card', false)
            } else {
              this.$emit('hide-lock-devices-card', true)
            }

            this.loading.devices.wilka = false
          },
          (e) => {
            this.loading.devices.wilka = false
            console.error(e)
          }
      )
    },

    getLockDevices() {
      this.$rhRequest.sendGet({
            endpoint: 'devices/get?deviceTypes=kaadas-lock,nuki-lock',
          },
          (r) => {
            if (r?.data?.code === -1) {
              this.loading.devices.other = false
              return
            }

            let devices = JSON.parse(JSON.stringify(this.devices))

            Object.values(r?.data?.data).forEach((e) => {
              let device = {
                icon: 'lock',
                id: e.id,
                name: e.name,
                batteryLevel: e['battery-level'],
                type: this.$t(`${e['type']}.type.label`),
                protocol: 'zwave',
                device: e
              }

              let index = devices.findIndex(e => e.id === device.id)
              if (index != -1) {
                devices[index] = device
              } else {
                devices.push(device)
              }
            })
            this.devices = devices
            this.loading.devices.other = false
          },
          (e) => {
            console.error(e)
            this.loading.devices.other = false
          }
      )
    },

    mapBatteryLevels(list) {
      let batteryLevels = new Map();
      if (typeof list == 'undefined' || list === null) return batteryLevels;
      list.forEach((e) => {
        batteryLevels.set(e.id, e.attributes.value)
      })
      return batteryLevels;
    },

    openLockDevice(row) {
      if (row.protocol === 'wilka') {
        this.$root.bisadialog.toggle('wilkaLock', true, {params: {
            name: row.name,
            bluetoothId: row.bluetoothId,
            id: row.id,
            ownership: row.ownership,
            type: row.type
          }})
        this.$root.bisadialog.callDialogInit('wilkaLock')
      } else {
        this.$root.bisadialog.toggle('device', true, {device: row.device})
        this.$root.bisadialog.callDialogInit('device')
      }
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getDevices()
      }, config.updateInterval)
    },

    getDevices() {
      this.getWilkaDevices()
      this.getLockDevices()
    },
    loadCachedData() {
      this.$caching.getData('locklist-devices').then(devices => {
        if (devices !== -1) {
          if (!devices) {
            this.devices = []
          } else {
            this.devices = devices
          }
        }
      })
    },
    showSkeletonLoader() {
      this.loading.devices.wilka = true
      this.loading.devices.other = true
    },
    isLoading() {
      return !(this.loading.devices.wilka === false && this.loading.devices.other === false)
    }
  },
  beforeDestroy() {
    this.$root.$off('wilka-lock-dialog-close')
    this.$root.$off('updateDeviceList')
    clearInterval(this.timer)
  },
  mounted() {
    this.showSkeletonLoader()
    this.loadCachedData()

    requestHelper.startDelayedRequest(
        () => this.getDevices(),
        () => this.updateData()
    )

    this.$root.$on('wilka-lock-dialog-close', () => {
      this.getDevices()

      requestHelper.startDelayedRequest(
          () => this.getDevices(),
          () => this.updateData()
      )

      // update device list after adding/removing devices
      this.$root.$on('updateDeviceList', () => {
        this.getDevices()

      })
    })
  },
  watch: {
    devices() {
      this.$caching.saveData('locklist-devices', this.devices)
    }
  }
}
</script>

<style lang="scss">
.width-20 {
  width: 20px;
}
</style>
