<template>
  <div>
    <div v-html="$t('add-gateway-not-found.description', {gwId: '<code>' + gwId + '</code>'})"/>
    <div class="mt-8 mb-2 error--text font-weight-bold" v-html="$t('add-gateway-not-found.tips.headline')"/>

    <v-list color="transparent">
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="error"
                  class="material-icons-outlined">
            check_circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('add-gateway-not-found.tips.0')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="error"
                  class="material-icons-outlined">
            check_circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('add-gateway-not-found.tips.1')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="error"
                  class="material-icons-outlined">
            check_circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('add-gateway-not-found.tips.2')"/>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon class="mr-3">
          <v-icon color="error"
                  class="material-icons-outlined">
            check_circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-03 text-wrap"
                             v-html="$t('add-gateway-not-found.tips.3')"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {
  name: "AddGatewayNotFound",

  props: ['gwId']

}
</script>
