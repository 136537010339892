<template>
  <entry-frame :title="$t('registration-selection-page.title').toString()">
    <template v-slot:subheader>
      <div v-html="$t('registration-selection-page.subtitle')"/>
      <v-btn text small
             color="primary"
             class="font-weight-bold"
             to="/login">
        {{ $t('registration-selection-page.login') }}
      </v-btn>
    </template>

    <template v-slot:form>
      <!-- create new account card -->
      <v-card class="mb-6 registration-switch-card"
              to="/registrationEmail">
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-subtitle class="mt-1 d-flex">
            <v-icon class="align-self-center pr-3 mx-auto material-icons-outlined"
                    color="primary">
              account_circle
            </v-icon>
            <div class="align-self-center font-weight-bold mx-auto font-size-04"
                 v-html="$t('registration-switch.email-label')"></div>
          </v-card-subtitle>
          <v-card-actions>
            <v-icon color="primary" class="mt-3 material-icons-outlined">
              chevron_right
            </v-icon>
          </v-card-actions>
        </div>
      </v-card>

      <div class="text-center">
        <v-btn text small
               color="primary"
               class="font-weight-bold mt-2 wrap-text"
               @click="$root.bisadialog.toggle('redeemCode')">
          {{ $t('registration-selection-page.redeem-code-button') }}
        </v-btn>
      </div>
    </template>
  </entry-frame>
</template>

<script>
import EntryFrame from "@/templates/components/EntryFrame";
import config from "@/config/config.app.json";

export default {
  name: 'RegistrationSelection',

  components: {
    EntryFrame
  },
  methods: {
    openRedeemCodeDialog() {
      this.$root.bisadialog.toggle('redeemCode', null, { code: this.$route.params.code }  );
      this.$root.bisadialog.callDialogInit('redeemCode')
    }
  },
  computed: {
    canInviteAdditionalUser() {
      return !(config.canInviteAdditionalUser === 'false');
    }
  },
  watch: {
    $route() {
      if (this.$route.params.code && this.$route.params.code.length === 6) {
        this.openRedeemCodeDialog();
      }
    }
  },
  mounted() {
    if (this.$route.params.code && this.$route.params.code.length === 6) {
      this.$nextTick(() => {
        this.openRedeemCodeDialog()
      })
    } else {
      console.error('Invalid code:', this.$route.params.code);
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/entry/registration.scss';
</style>
