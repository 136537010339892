<template>
  <v-stepper-content step="12"
                     class="pa-1">

    <div v-html="$t('add-rule-step-set-point-definition.info-text')"/>

    <!-- switch devices -->
    <content-card :title="$t('add-rule-step-set-point-definition.set-point.title').toString()"
                  v-if="item?.actuator?.dataType === 'boolean'"
                  icon="tune"
                  class="my-5">
      <template v-slot:content>
        <v-list class="pa-0">
          <v-radio-group v-model="basicSetPoint"
                         class="pa-0 ma-0 overflow-visible"
                         hide-details
                         @change="updateBasicSetPoint">

            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-radio value="1"
                         class="font-weight-bold"
                         :label="$t('add-rule-step-set-point-definition.turn-on')"/>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="list-item straight">
              <v-list-item-content class="overflow-visible">
                <v-radio value="0"
                         class="font-weight-bold"
                         :label="$t('add-rule-step-set-point-definition.turn-off')"/>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>
        </v-list>
      </template>
    </content-card>
  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import automations from "@/scripts/automations";

export default {
  name: 'AddRuleStepBasicSetActionDefinition',
  components: {
    ContentCard
  },

  props: {
    item: {
      type: Object,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },

  data: function () {
    return {
      basicSetPoint: null
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      if (this.basicSetPoint !== null && (this.basicSetPoint === "0" || this.basicSetPoint === "1")) {
        this.updateModel() // refresh v-model to current value
        return true
      }
      return false
    }
  },

  methods: {
    updateBasicSetPoint() {
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        deviceId: this.item?.device?.id,
        basicSetPoint: this.basicSetPoint,
        setPoint: null,
        switchOperation: null,
        property: this.item?.actuator?.name,
        type: automations.actionTypes.devices // JSON:API Resource type
      })
    }
  }
};
</script>
