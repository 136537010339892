import config from "@/config/config.app.json";

export default class IndexedDBCache {
    constructor(dbName) {
        this.dbName = dbName
        this.storeName = localStorage.getItem('activeSite')
        this.version = 1
        this.db = null
        this.openingDB = null
        let appVersion = localStorage.getItem('appVersion')
        if (appVersion === null) {
            localStorage.setItem('appVersion', config.configVersion)
        }
        if (appVersion === null || appVersion !== config.configVersion) {
            this.deleteDatabase()
            localStorage.setItem('appVersion', config.configVersion)
        }
        this.initDB()
    }

    /**
     * Initialize IndexedDB database
     *
     * @async
     * @param {Number} tries - Number of times to retry opening the database in case of failure
     * @returns {Promise}
     */
    async initDB(tries = 3) {
        if (this.openingDB) {
            await this.openingDB
            if (this.db && this.db.version === this.version) {
                return
            }
        }
        let promise = new Promise((resolve, reject) => {
            if (!window.indexedDB) {
                reject("IndexedDB not supported")
                return
            }
            if (this.db) {
                this.db.close()
                this.db = null
            }
            this.storeName = localStorage.getItem('activeSite')
            let request
            if (this.version === 1) {
                request = window.indexedDB.open(this.dbName)
            } else {
                request = window.indexedDB.open(this.dbName, this.version)
            }

            request.onerror = () => {
                if (tries > 0) {
                    console.error("Database error, retrying...")
                    this.initDB(tries - 1).then(event => {
                        this.db = event.target.result
                        resolve(event)
                    }).catch((event) => {
                        console.error("Database error", event)
                        reject(event)
                    })
                }
            }

            request.onsuccess = (event) => {
                this.db = event.target.result
                resolve(event)
            }

            request.onupgradeneeded = (event) => {
                const db = event.target.result
                db.createObjectStore(this.storeName, {keyPath: "id"})
                resolve(event)
            }

            request.onblocked = () => {
                if (tries > 0) {
                    console.error("Database blocked, retrying...")
                    this.initDB(tries - 1).then(event => {
                        this.db = event.target.result
                        resolve(event)
                    }).catch((event) => {
                        console.error("Database blocked", event)
                        reject(event)
                    })
                }
            }
        })
        this.openingDB = promise
        return promise
    }

    /**
     * Save data to IndexedDB
     *
     * @async
     * @param {String} id - Unique identifier for the data
     * @param {any} data - Data to be saved
     * @param {Number} tries - Number of times to retry saving data
     * @returns {Promise}
     */
    async saveData(id, data, tries = 3) {
        var functionId = Math.random()
        if (this.openingDB && this.openingDB.status === "pending") {
            await this.openingDB
        }
        if (this.openingDB && this.openingDB.status === "rejected") {
            await this.initDB()
        }
        if (!this.db) {
            await this.initDB()
        }
        try {
            this.storeName = localStorage.getItem('activeSite')
            if (!this.db.objectStoreNames.contains(this.storeName)) {
                this.version = this.db.version
                this.version++
                await this.initDB()
            }

            const transaction = this.db.transaction([this.storeName], "readwrite")
            const objectStore = transaction.objectStore(this.storeName)
            const request = objectStore.put({id, data})

            request.onerror = async (event) => {
                if (tries > 0) {
                    console.error("Error saving data, retrying...", functionId, Date.now())
                    await this.saveData(id, data, tries - 1).then(() => {
                    }).catch((event) => {
                        console.error("Error saving data, retrying...", event)
                    })
                }
                console.error("Error saving data:", event.target.error, functionId, Date.now())
            }
        } catch (error) {
            if (tries > 0) {
                console.error("Error saving data, retrying... Tries remaining:", tries, functionId, Date.now())
                await this.saveData(id, data, tries - 1).then(() => {
                }).catch((event) => {
                    console.error("Error saving data", event)
                })
            }
        }
    }

    /**
     * Retrieve data from IndexedDB
     *
     * @async
     * @param {String} id - Unique identifier for the data
     * @param tries {Number} - Number of times to retry retrieving data
     * @returns {Promise} - The data associated with the given id, or -1 if no data exists for the given id
     */
    async getData(id, tries = 3) {
        var functionId = Math.random()
        if (this.openingDB && this.openingDB.status === "pending") {
            await this.openingDB
        }
        let promise
        if (!this.db) {
            promise = this.initDB();
            await promise
        }
        try {
            if (!this.db.objectStoreNames.contains(this.storeName)) {
                this.version = this.db.version
                this.version++
                await this.initDB()
            }
            const transaction = this.db.transaction([this.storeName], "readonly")
            const objectStore = transaction.objectStore(this.storeName)

            const request = objectStore.get(id)

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    if (!request.result) {
                        this.saveData(id, null)
                        resolve(-1)
                        return
                    }
                    resolve(request.result.data)
                }

                request.onerror = (event) => {
                    if (tries > 0) {
                        console.error("Error retrieving data, retrying...", id, functionId, Date.now())
                        this.getData(id, tries - 1).then((data) => {
                            resolve(data)
                        }).catch((event) => {
                            console.error("Error retrieving data, retrying...", event)
                            reject(event)
                        })
                        return
                    }
                    console.error("Error retrieving data:", id, event.target.error)
                    reject(event.target.error)
                }
            })
        } catch (error) {
            if (tries > 0) {
                console.error("Error retrieving data, retrying... Tries remaining:", tries, functionId, Date.now())
                return this.getData(id, tries - 1)
            }
            console.error("Error retrieving data:", id, error)
            return null
        }
    }

    /**
     * Delete the database
     */
    deleteDatabase() {
        // Delete complete database
        if (!this.db) {
            window.indexedDB.deleteDatabase(this.dbName)
            return
        }
        this.db.close()
        this.db = null
        window.indexedDB.deleteDatabase(this.dbName)
    }
}