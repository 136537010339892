<!--
  Renders one actuator subdevice.
-->
<template v-slot="{ dense:dense }">
  <div>
    <!-- single block dense view -->
    <div v-if="dense && singleBlock">
      <slot/>
    </div>

    <!-- normal dense view -->
    <v-tab v-else-if="dense"
           class="mx-auto"
           :ripple="false">
      <slot/>
    </v-tab>

    <!-- large view -->
    <v-list-item v-else
                 class="list-item">
      <v-list-item-icon v-if="icon">
        <v-icon :class="isNonFlipIcon(icon) ? 'non-flip' : ''" :color="color">
          {{ icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-size-03" v-html="label"/>
        <slot name="content"/>
      </v-list-item-content>
      <v-list-item-action class="ml-1"
                          @click.stop="/*prevent parent event*/">
        <v-btn icon v-if="!hideHistory"
               @click.stop="showEventHistory = !showEventHistory">
          <v-icon class="non-flip" :color="showEventHistory ? 'primary' : ''">history</v-icon>
        </v-btn>
        <slot v-if="!hideActionsSlot"/>
      </v-list-item-action>
    </v-list-item>

    <!-- device event history display -->
    <device-event-history v-if="showEventHistory"
                          :device-id="deviceId"
                          :event="actuator"/>
  </div>
</template>

<script>
import DeviceEventHistory from "@/templates/components/devices/DeviceEventHistory.vue";
import {isNonFlipIcon} from "@/i18n";
import deviceProperties from "@/config/deviceProperties.json";

export default {
  name: 'ActuatorDevice',
  methods: {isNonFlipIcon},
  components: {DeviceEventHistory},

  props: {
    actuator: Object,
    deviceId: Number,
    dense: Boolean,
    singleBlock: Boolean,
    label: String,
    icon: String,
    color: {
      type: String,
      default: 'primary'
    },
    hideActionsSlot: Boolean
  },

  data: function () {
    return {
      showEventHistory: false
    }
  },

  computed: {
    hideHistory() {
      return deviceProperties[this.actuator?.name]?.view?.hideHistory === true
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/device.scss';
</style>
