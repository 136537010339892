<!--
  This template gets all devices for the currently active site from the API and shows a devices list (or empty-state).
-->
<template>
  <div>
    <v-skeleton-loader v-if="loading.devices"
                       class="mb-5"
                       type="table"/>

    <content-card v-else
                  color="primary"
                  class="mb-5"
                  :title="$t('device-list.title').toString()"
                  icon="widgets"
                  dense>

      <template v-slot:titleBar v-if="devices?.length > 1">
        <v-text-field class="pt-md-0 pt-2 float-right search-input non-flip"
                      dense rounded outlined
                      v-model="search"
                      append-icon="search"
                      :label="$t('device-list.search')"
                      single-line
                      hide-details
                      @focus="showFilterOptions = true"/>

      </template>

      <template v-slot:content>
        <v-list-item v-if="showFilterOptions" class="appBackground">
          <v-list-item-content>
            <v-select outlined dense
                      class="pr-4 my-3 filter-select"
                      v-model="sortDevicesBy"
                      :label="$t('device-list.sort-by')"
                      :items="sortableColumns"
                      prepend-inner-icon="sort"
                      hide-details
                      item-text="name"
                      item-value="key">
              <template v-slot:prepend-item>
                <v-btn plain
                       class="mx-2 mb-2 px-2"
                       @click="sortDesc = !sortDesc">
                  <v-icon left v-if="sortDesc">vertical_align_bottom</v-icon>
                  <v-icon left v-else>vertical_align_top</v-icon>
                  <span v-html="sortDesc ? $t('device-list.sort.descending') : $t('device-list.sort.ascending')"/>
                </v-btn>
              </template>
            </v-select>

            <v-select outlined dense
                      class="pr-4 my-3 filter-select"
                      v-model="groupDevicesBy"
                      :label="$t('device-list.group-by')"
                      :items="groupableColumns"
                      prepend-inner-icon="category"
                      hide-details
                      item-text="name"
                      item-value="key"/>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon
                   @click="showFilterOptions=false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-data-table :headers="headers"
                      :items="devices"
                      :search="search"
                      item-key="id"
                      :sort-by="sortDevicesBy"
                      :sort-desc="sortDesc"
                      :group-by="groupDevicesBy"
                      show-group-by
                      class="row-pointer tileBackground"
                      mobile-breakpoint="0"
                      :items-per-page="-1"
                      hide-default-header
                      hide-default-footer
                      @click:row="openDevice">

          <!-- empty state -->
          <template v-slot:no-data>
            <div v-if="timeout"
                 class="ma-8 mb-4"
                 v-html="$t('device-list.timeout')"/>
            <div v-else
                 class="ma-8 mb-4"
                 v-html="$t('device-list.no-data')"/>
          </template>

          <!-- no match for search -->
          <template v-slot:no-results>
            <div class="ma-8 mb-4" v-html="$t('device-list.no-results')"/>
          </template>

          <!-- customize grouping header -->
          <!-- eslint-disable-next-line -->
          <template v-slot:group.header="{items, isOpen, toggle}">
            <th :colspan="headers.length">
              <v-icon @click="toggle"
                      class="mr-2">
                {{ isOpen ? 'expand_less' : 'expand_more' }}
              </v-icon>
              <span v-if="groupDevicesBy === 'typeLabel'" v-html="items[0].typeLabel"/>
              <span v-if="groupDevicesBy === 'online'"
                    v-html="items[0].online ? $t('app.online') : $t('app.offline')"/>
              <v-icon @click="groupDevicesBy = null"
                      class="float-right">
                close
              </v-icon>
            </th>
          </template>

          <!-- customize item title -->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.name="{ item }">

            <v-list-item class="pa-1">
              <v-list-item-icon class="mr-2">
                <device-icon :device-type="item?.type" :is-colorized="isOnline(item)" />
              </v-list-item-icon>
              <v-list-item-content class="device-title">
                <v-list-item-title :class="isOnline(item) ? 'primary--text font-weight-bold' :''"
                                   class="font-size-03">
                  {{ item.name ?? $t('device-list.unsupported-device') }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-size-02"
                                      v-text="item.typeLabel"/>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- shows basic device values for larger table widths -->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.value="{ item }">
            <v-chip v-if="typeof item.state === 'boolean'"
                    :color="item.state ? 'primary' : ''"
                    class="mr-1 my-1" small>
              {{ item.state ? $t('device-list.state.on') : $t('device-list.state.off') }}
            </v-chip>
            <v-chip v-if="typeof item.sensorState === 'boolean'"
                    :color="item.sensorState ? 'primary' : ''"
                    class="mr-1 my-1" small>
              {{ item.sensorState ? $t('device-dialog.state.active') : $t('device-dialog.state.inactive') }}
            </v-chip>
            <v-chip v-for="(property, idx) in item.sensors" v-bind:key="'s'+idx"
                    v-show="property.type !=='boolean' && property.value && property.unit"
                    class="mr-1 my-1" small>
              {{ formatValue(property) }}
            </v-chip>
            <span v-if="item.deviceWhitelistKey !== 'RMT_ZXT800'">
              <v-chip v-for="(property, idx) in item.actuators" v-bind:key="'a'+idx"
                      v-show="property.type !=='boolean'"
                      class="mr-1 my-1" small>
                {{ formatValue(property) }}
              </v-chip>
            </span>
          </template>

          <!-- device status -->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.online="{ item }">
            <v-icon v-if="item['battery-low']"
                    color="warning">
              battery_alert
            </v-icon>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="!isOnline(item)"
                        class="material-icons-outlined non-flip"
                        color="error"
                        v-bind="attrs"
                        v-on="on">
                  offline_bolt
                </v-icon>
              </template>
              <span v-html="$t('device.offline')"/>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="isOnline(item)"
                        class="material-icons-outlined"
                        color="success"
                        v-bind="attrs"
                        v-on="on">
                  contactless
                </v-icon>
              </template>
              <span v-html="$t('device.online')"/>
            </v-tooltip>

            <v-tooltip bottom max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="item.deviceWhitelistKey === 'MEC_METER' && item.mecMeterStatus === 'not-configured'"
                        class="material-icons-outlined"
                        color="warning"
                        v-bind="attrs"
                        v-on="on">
                  report_problem
                </v-icon>
              </template>
              <span v-html="$t('device-list.mec-meter.not-configured')" />
            </v-tooltip>

          </template>

          <!-- chevron -->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.chevron="{ item }">
            <v-icon color="primary">chevron_right</v-icon>
          </template>
        </v-data-table>
      </template>
    </content-card>

  </div>
</template>

<script>

import config from "@/config/config.app.json";
import deviceTypeIcons from "@/config/deviceTypeIcons.json";
import deviceProperties from "@/config/deviceProperties.json";
import ContentCard from "@/templates/components/ContentCard";
import requestHelper from "@/scripts/requestHelper";
import formats from "@/scripts/formats";
import DeviceIcon from "@/templates/components/devices/DeviceIcon.vue";

export default {
  name: "DeviceList",
  computed: {
    formats() {
      return formats
    }
  },

  components: {DeviceIcon, ContentCard},

  data() {
    return {
      timer: null,
      loading: {
        devices: false
      },
      timeout: false,
      headers: [
        {
          align: 'start',
          value: 'name',
          cellClass: 'device-name-column'
        },
        {
          value: 'value',
          align: 'left d-none d-sm-table-cell'
        },
        {
          value: 'online',
          align: 'right',
          cellClass: 'px-1'
        },
        {
          value: 'chevron',
          align: 'right',
          cellClass: 'pl-0 width-50'
        }
      ],
      search: null,
      sortableColumns: [
        {name: this.$t('device-list.sort.device-name'), key: 'name'},
        {name: this.$t('device-list.sort.device-type'), key: 'typeLabel'},
        {name: this.$t('device-list.sort.device-status'), key: 'online'},
        {name: this.$t('device-list.sort.natural'), key: null},
      ],
      sortDevicesBy: 'name',
      sortDesc: false,
      groupableColumns: [
        {name: this.$t('device-list.group.none'), key: null},
        {name: this.$t('device-list.sort.device-type'), key: 'typeLabel'},
        {name: this.$t('device-list.sort.device-status'), key: 'online'},
      ],
      groupDevicesBy: null,
      showFilterOptions: false,
      devices: []
    }
  },

  methods: {
    /**
     * gets all smart home devices for the currently active site from the API
     *
     * @param showError
     */
    getDevices(showError) {
      this.$rhRequest.sendGet({
        endpoint: "devices/get",
      }, (resp) => {
        this.timeout = false
        let deviceList = Object.values(resp?.data?.data)
        // remove gateway from the device list
        this.devices = deviceList.filter(device => !['controller', 'kaadas-lock', 'nuki-lock'].includes(device.type))
        this.loading.devices = false
      }, (err) => {
        console.error(err)
        if (err.code === 'ECONNABORTED') {
          this.timeout = true
        }
        if (showError) { // do not spam
          this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
        }
        this.loading.devices = false
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      let self = this
      this.timer = setInterval(function () {
        self.getDevices(false)
      }, config.updateInterval)
    },

    /**
     * returns true if the device is online
     *
     * @param device
     * @returns {boolean}
     */
    isOnline(device) {
      return device.online && device.gatewayOnline
    },

    /**
     * returns if it's md-icon or md-symbol for a passed device type
     *
     * @param type
     * @returns {boolean}
     */
    isIcon(type) {
      if (Object.keys(deviceTypeIcons).includes(type)) {
        return deviceTypeIcons[type].type === 'md-icon'
      }
      return true
    },

    /**
     * returns a matching icon for a passed device type
     *
     * @param type
     * @returns {string}
     */
    getIcon(type) {
      return deviceTypeIcons[type]?.icon ?? 'widgets'
    },

    /**
     * opens a device page
     *
     * @param device
     */
    openDevice(device) {
      this.$root.bisadialog.toggle('device', true, {device: device})
      this.$root.bisadialog.callDialogInit('device')
    },

    //Loads DeviceSorting and Grouping settings from local storage
    loadDeviceSorting() {
      const sorting = JSON.parse(localStorage.getItem('deviceSorting')) || {
        sortBy: 'name',
        sortDesc: false,
        groupBy: null
      }
      this.sortDevicesBy = sorting.sortBy
      this.sortDesc = sorting.sortDesc
      this.groupDevicesBy = sorting.groupBy
    },

    //Saves DeviceSorting and Grouping settings to local storage
    saveDeviceSorting() {
      localStorage.setItem('deviceSorting', JSON.stringify({
        sortBy: this.sortDevicesBy,
        sortDesc: this.sortDesc,
        groupBy: this.groupDevicesBy
      }))
    },

    /**
     * Returns the mapped value translation or the value with the unit.
     * @param property
     * @returns {*|null|string}
     */
    formatValue(property) {
      if (deviceProperties[property?.name]?.deviceListValueMapping) {
        return this.$t(deviceProperties[property?.name].deviceListValueMapping[property.value]) ?? property.value
      } else {
        return formats.localize(property) + ' ' + property.unit
      }
    },
    loadCachedData() {
      this.$caching.getData('devices').then(devices => {
        if (devices !== -1) {
          if (!devices) {
            this.devices = []
          } else {
            this.devices = devices
          }
        }
      })
    }
  },

  mounted() {
    this.loading.devices = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
        () => this.getDevices(true),
        () => this.updateData()
    )
    // update device list after adding/removing devices
    this.$root.$on('updateDeviceList', () => {
      this.getDevices(false)
    })
    this.loadDeviceSorting()
  },

  beforeDestroy() {
    this.$root.$off('updateDeviceList')
    clearInterval(this.timer)
    this.saveDeviceSorting()
  },

  watch: {
    sortDevicesBy() {
      this.saveDeviceSorting()
    },
    groupDevicesBy() {
      this.saveDeviceSorting()
    },
    sortDesc() {
      this.saveDeviceSorting()
    },
    devices() {
      this.$caching.saveData('devices', this.devices)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/smarthome-page.scss';

.width-50 {
  width: 50px;
}
</style>
