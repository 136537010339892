<template>
  <!-- No dense view: Nuki devices cannot be added to favorites. This is covered by AccessesCard.vue -->
  <device-card flat
               :device="device"
               :title="$t('device-card-nuki-bridge.title').toString()"
               icon="tune">

    <div class="pa-5">
      <v-btn depressed block large
           class="font-weight-bold"
           :disabled="btnDisabled"
           color="primary"
           v-html="$t('device-card-nuki-bridge.discover')"
           @click="searchLocks"/>
    </div>
  </device-card>
</template>

<script>
import DeviceCard from "@/templates/components/devices/DeviceCard";

export default {
  name: 'DeviceCardNukiBridge',
  components: {DeviceCard},
  props: {
    device: Object,
    flat: Boolean
  },
  data: function () {
    return {
      btnDisabled: false
    }
  },
  methods: {
    searchLocks() {
      this.btnDisabled = true
      this.$rhRequest.sendPost({
        endpoint: 'nuki/search-locks-on-bridge',
        data: {
          deviceId: this.device.id,
        }
      }, () => {
        this.$root.bisatoast.success({message: this.$t('device-card-nuki-bridge.success')})
        setTimeout(() => {
          this.btnDisabled = false
        }, 3000);
      }, (err) => {
        this.btnDisabled = false
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        console.error(err)
      })
    }
  }
}

</script>
