<template>
  <fullscreen-overlay-frame :title="title.toString()"
                            icon="settings_suggest"
                            color="primary"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>
      <v-alert v-if="data?.rule?.id && data?.rule?.attributes?.synced === 'FAILED'"
               text
               class="font-size-03"
               icon="sync_problem"
               border="left"
               type="warning">
        <div v-text="$t('add-rule-dialog.not-synced')"/>
        <v-btn outlined small
               color="warning"
               class="mt-3 float-right"
               :loading="syncing"
               @click="syncRule">
          {{ $t('add-rule-dialog.not-synced.button') }}
        </v-btn>
      </v-alert>

      <v-stepper flat
                 v-model="step">
        <v-stepper-items class="overflow-visible">
          <add-rule-step-overview v-model="ruleProps"
                                  :condition="condition"
                                  :action="action"
                                  :actionSubject="deviceSelection"
                                  :loading-condition="loadingCondition"
                                  :loading-action="loadingAction"
                                  @select-condition="selectCondition"
                                  @select-action="selectAction"/>

          <!-- condition type selection -->
          <add-rule-step-condition-type v-model="conditionSelection"/>
          <!-- timer condition -->
          <add-rule-step-timer-type v-model="timerType"/>
          <add-rule-step-timer-definition ref="timerDefinition"
                                          v-model="condition"
                                          :timer-type="timerType"/>
          <!-- device property condition -->
          <add-rule-step-condition-device-selection ref="conditionDeviceSelection"
                                                    v-model="conditionDeviceSelection"/>
          <add-rule-step-property-definition v-model="condition"
                                             :device="conditionDeviceSelection"
                                             ref="propertyDefinition"/>

          <!-- action type selection -->
          <add-rule-step-action-type ref="actionSelection"
                                     v-model="actionType"
                                     type="rule"/>
          <!-- device action -->
          <add-rule-step-device-selection ref="deviceSelection"
                                          v-model="deviceSelection"/>
          <!-- special case: ir-controller :/ -->
          <add-rule-step-i-r-controller-action-definition v-if="deviceSelection?.device?.type === 'infrared-controller'"
                                                          ref="setPointDefinition"
                                                          v-model="action"
                                                          :item="deviceSelection"/>
          <!-- special case: Basic Set (Shelly2PM/Masa (secure)) -->
          <add-rule-step-basic-set-action-definition v-else-if="deviceSelection?.device?.useSetPoint === true"
                                                       ref="setPointDefinition"
                                                       v-model="action"
                                                       :item="deviceSelection"/>

          <!-- special case: double-smart-module :/ -->
          <add-rule-step-double-smart-module-action-definition v-else-if="deviceSelection?.device?.type === 'double-smart-module'"
                                                          ref="setPointDefinition"
                                                          v-model="action"
                                                          :item="deviceSelection"/>

          <!-- special case: Remotec Thermostat :/ -->
          <add-rule-step-remotec-thermostat-set-point-definition
              v-else-if="deviceSelection?.device?.type === 'rmt-heating'"
              ref="setPointDefinition"
              v-model="action"
              :item="deviceSelection"
          ></add-rule-step-remotec-thermostat-set-point-definition>

          <!-- every other device type -->
          <add-rule-step-set-point-definition v-else
                                              ref="setPointDefinition"
                                              v-model="action"
                                              :item="deviceSelection"/> <!-- rename to deviceAction and add json:api types? -->
          <!-- scene action -->
          <add-rule-step-scene-selection ref="sceneSelection"
                                         v-model="action"/>
          <!-- notification action -->
          <add-rule-step-notification-type-selection ref="notificationTypeSelection"
                                                     v-model="notificationTypeSelection"/>
          <add-rule-step-notification-definition ref="notificationDefinition"
                                                 v-model="action"
                                                 :type="notificationTypeSelection"/>
        </v-stepper-items>
      </v-stepper>
    </template>

    <template v-slot:actions>
      <!-- next button -->
      <v-btn v-if="step > 1 && step <= 6  || step >= 10 && step <= 15"
             large depressed
             :disabled="buttonDisabled"
             color="primary"
             class="font-weight-bold action-button"
             @click="nextButtonClick">
        {{ nextButtonLabel }}
      </v-btn>

      <!-- back button -->
      <v-btn v-if="step >= 2 && step <= 6 || step >= 10"
             large depressed outlined
             color="primary"
             class="mt-4 ml-0 action-button"
             @click="previousStep">
        {{ $t('app.step-back') }}
      </v-btn>

      <!-- save button -->
      <v-btn v-if="step === 1"
             :disabled="!(condition && action) || loading || !isSiteAdmin"
             :loading="loading"
             large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="save">
        <v-icon left>add</v-icon>
        {{ $t('add-rule-dialog.save') }}
      </v-btn>

      <!-- delete button -->
      <v-btn v-if="step === 1 && data?.rule"
             :disabled="loading || !isSiteAdmin"
             large depressed outlined
             color="primary"
             class="mt-4 ml-0 font-weight-bold action-button"
             @click="deleteRule">
        <v-icon left>delete</v-icon>
        {{ $t('add-rule-dialog.delete') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import AddRuleStepTimerType from "@/templates/dialogs/automations/AddRuleStepTimerType.vue";
import AddRuleStepTimerDefinition from "@/templates/dialogs/automations/AddRuleStepTimerDefinition.vue";
import AddRuleStepActionType from "@/templates/dialogs/automations/AddRuleStepActionType.vue";
import AddRuleStepOverview from "@/templates/dialogs/automations/AddRuleStepOverview.vue";
import AddRuleStepConditionType from "@/templates/dialogs/automations/AddRuleStepConditionType.vue";
import AddRuleStepDeviceSelection from "@/templates/dialogs/automations/AddRuleStepDeviceSelection.vue";
import AddRuleStepSetPointDefinition from "@/templates/dialogs/automations/AddRuleStepSetPointDefinition.vue";
import Vue from "vue";
import moment from "moment";
import AddRuleStepSceneSelection from "@/templates/dialogs/automations/AddRuleStepSceneSelection.vue";
import automations from "@/scripts/automations";
import AddRuleStepPropertyDefinition from "@/templates/dialogs/automations/AddRuleStepPropertyDefinition.vue";
import AddRuleStepConditionDeviceSelection
  from "@/templates/dialogs/automations/AddRuleStepConditionDeviceSelection.vue";
import AddRuleStepIRControllerActionDefinition
  from "@/templates/dialogs/automations/specialCases/AddRuleStepIRControllerActionDefinition.vue";
import AddRuleStepBasicSetActionDefinition from "@/templates/dialogs/automations/specialCases/AddRuleStepBasicSetActionDefinition.vue";
import AddRuleStepNotificationTypeSelection
  from "@/templates/dialogs/automations/AddRuleStepNotificationTypeSelection.vue";
import AddRuleStepNotificationDefinition from "@/templates/dialogs/automations/AddRuleStepNotificationDefinition.vue";
import AddRuleStepDoubleSmartModuleActionDefinition from "@/templates/dialogs/automations/specialCases/AddRuleStepDoubleSmartModuleActionDefinition.vue";
import AddRuleStepRemotecThermostatSetPointDefinition from "@/templates/dialogs/automations/specialCases/AddRuleStepRemotecThermostatSetPointDefinition.vue";

export default {
  name: 'AddRuleDialog',
  components: {
    AddRuleStepRemotecThermostatSetPointDefinition,
    AddRuleStepDoubleSmartModuleActionDefinition,
    AddRuleStepNotificationDefinition,
    AddRuleStepNotificationTypeSelection,
    AddRuleStepIRControllerActionDefinition,
    AddRuleStepBasicSetActionDefinition,
    AddRuleStepConditionDeviceSelection,
    AddRuleStepPropertyDefinition,
    AddRuleStepSceneSelection,
    AddRuleStepSetPointDefinition,
    AddRuleStepDeviceSelection,
    AddRuleStepConditionType,
    AddRuleStepOverview,
    AddRuleStepActionType,
    AddRuleStepTimerDefinition,
    AddRuleStepTimerType,
    FullscreenOverlayFrame,
  },

  props: ['data'],

  data: function () {
    return {
      /**
       * Step IDs:
       * 1 - overview
       * 2 - condition type selection
       * 3 - timer type selection
       * 4 - timer definition
       * 5 - device condition property selection
       * 6 - device property condition definition
       * 10 - action type selection
       * 11 - device selection
       * 12 - set point definition
       * 13 - scene selection
       * 14 - notification type selection
       * 15 - notification definition
       */
      step: 1,
      title: this.$t('add-rule-dialog.overview.title'),
      ruleProps: {
        name: this.data?.rule?.attributes?.name ?? this.$t('add-rule-dialog.new-rule.default-name'),
        iconKey: this.data?.rule?.attributes?.iconKey ?? 'auto_awesome',
        deactivated: this.data?.rule?.attributes?.deactivated ?? false
      },
      timerType: 'SINGULAR', // preselection
      condition: null,
      conditionSelection: null,
      actionType: automations.actionTypes.devices, // preselection
      deviceSelection: null,
      conditionDeviceSelection: null,
      conditionDefinition: null,
      action: null,
      loading: false,
      ruleId: null, // only set when editing an existing rule
      loadingCondition: false,
      loadingAction: false,
      syncing: false,
      notificationTypeSelection: null
    }
  },

  computed: {
    nextButtonLabel() {
      if (this.step === 12
          || this.step === 13
          || this.step === 15
          || (this.step === 14 && this.notificationTypeSelection === 'PUSH')) {
        return this.$t('add-rule-dialog.add-action')
      } else if (this.step === 4 || this.step === 6) {
        return this.$t('add-rule-dialog.add-condition')
      } else if (this.step > 1 && this.step < 6 || this.step >= 10 && this.step < 15) {
        return this.$t('app.continue')
      }
      return ""
    },

    buttonDisabled() {
      switch (this.step) {
        case 4:
          return !this.$refs.timerDefinition?.valid
        case 5:
          return !this.$refs.conditionDeviceSelection?.valid
        case 6:
          return !this.$refs.propertyDefinition?.valid
        case 11:
          return !this.$refs.deviceSelection?.valid
        case 12:
          return !this.$refs.setPointDefinition?.valid
        case 13:
          return !this.$refs.sceneSelection?.valid
        case 14:
          return !this.$refs.notificationTypeSelection?.valid
        case 15:
          return !this.$refs.notificationDefinition?.valid
        default:
          return false
      }
    },

    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  },

  methods: {
    /**
     * Initializes the dialog with an existing rule. Used for editing a rule, so only call
     * init() if the user wants to edit an existing rule.
     */
    init() {
      this.ruleId = this.data.rule.id
      this.ruleProps.name = this.data.rule.attributes.name
      this.ruleProps.iconKey = this.data.rule.attributes.iconKey

      // init condition
      if (this.data.rule.attributes.condition && this.data.rule.attributes.condition.type === automations.conditionTypes.timers) {
        this.timerType = this.data.rule.attributes.condition.timerType
        this.conditionSelection = 'time'
        // wait for the watcher on timerType (AddRuleStepTimerDefinition) to avoid overrides
        Vue.prototype.$nextTick(() => {
          this.condition = this.data.rule.attributes.condition
        })
      } else if (this.data.rule.attributes.condition && (
          this.data.rule.attributes.condition.type === automations.conditionTypes.properties)
      ) {
        this.conditionSelection = 'devices'
        // wait for the watcher on timerType (AddRuleStepTimerDefinition) to avoid overrides
        Vue.prototype.$nextTick(() => {
          this.condition = this.data.rule.attributes.condition
        })
      } // add cases for other condition types here as soon as we support them

      // init action
      if (this.data.rule.attributes.action) {
        this.action = this.data.rule.attributes.action
        if (this.action.deviceId) {
          // get matching device selection if the action has type='devices'
          this.getDeviceActuatorItem(this.action.deviceId)
        }
      } // add cases for other action types here as soon as we support them
    },

    /**
     * action for the next button
     */
    nextButtonClick() {
      if (this.step === 4
          || this.step === 6
          || this.step === 12
          || this.step === 13
          || this.step === 15) {
        this.goToOverview()
      } else if (this.step === 14 && this.notificationTypeSelection === 'PUSH') {
        this.addPushNotificationAction()
        this.goToOverview()
      } else if (this.step === 2 && this.conditionSelection === automations.actionTypes.devices) {
        this.goToDeviceConditionSelection()
      } else if (this.step === 5) {
        this.goToPropertyDefinition()
      } else if (this.step === 10 && this.actionType === automations.actionTypes.devices) {
        this.goToDeviceActionSelection()
      } else if (this.step === 10 && this.actionType === automations.actionTypes.sceneRefs) {
        this.goToSceneActionSelection()
      } else if (this.step === 10 && this.actionType === automations.actionTypes.notifications) {
        this.goToNotificationTypeSelection()
      } else {
        this.nextStep()
      }
    },

    /**
     * loads the overview step
     */
    goToOverview() {
      this.step = 1
    },

    /**
     * loads and initializes the scene action selection step
     */
    goToSceneActionSelection() {
      if (!this.ruleId) {
        // the user is creating a new rule: init scene selection list without pre-selection
        this.$refs.sceneSelection.init()
      } else {
        this.$refs.sceneSelection.init(this.action)
      }
      this.step = 13
    },

    /**
     * loads and initializes the device action selection step
     */
    goToDeviceActionSelection() {
      if (!this.ruleId) {
        // the user is creating a new rule: init device selection list without pre-selection
        this.$refs.deviceSelection.init()
      } else {
        this.$refs.deviceSelection.init(this.deviceSelection);
      }

      this.step = 11
    },

    /**
     * loads and initializes the notification type selection step
     */
    goToNotificationTypeSelection() {
      this.step = 14
    },

    /**
     * proceeds to device selection
     */
    goToDeviceConditionSelection() {
      this.step = 5
    },

    /**
     * proceeds to device property definition
     */
    goToPropertyDefinition() {
      this.$refs.propertyDefinition.init()
      this.step = 6
    },

    /**
     * proceeds to condition selection
     */
    selectCondition() {
      this.step = 2
    },

    /**
     * proceeds to action selection
     */
    selectAction() {
      const deviceActionTypes = [
        automations.actionTypes.devices,
        automations.actionTypes.irControllers,
        automations.actionTypes.sgready,
        automations.actionTypes.rmtThermostat
      ];

      // init components when editing an existing action
      if (deviceActionTypes.includes(this.action?.type)) {
        this.actionType = automations.actionTypes.devices;
        // pre-select action type
        this.$refs.actionSelection.setActionType(this.actionType);
        // pre-select device
        this.$refs.deviceSelection.init(this.deviceSelection);
      } else if (this.action?.type === automations.actionTypes.sceneRefs) {
        // pre-select action type
        this.actionType = automations.actionTypes.sceneRefs;
        this.$refs.actionSelection.setActionType(this.actionType);
        // pre-select scene
        this.$refs.sceneSelection.init(this.action);
      }

      this.step = 10
    },

    /**
     * proceeds to next step
     */
    nextStep() {
      this.step++
    },

    /**
     * back to previous step
     */
    previousStep() {
      if (this.step === 5 ) {
       this.step = 2
      } else if(this.step === 10  || this.step === 13) {
        this.goToOverview()
      } else if (this.step === 14) {
        this.step = 10
      } else {
        this.step--
      }
    },

    /**
     * sets the action to notifications:push
     */
    addPushNotificationAction() {
      this.action = {
        type: automations.actionTypes.notifications,
        notificationType: 'PUSH'
      }
    },

    /**
     * saves the new rule
     */
    save() {
      this.loading = true

      // do not store metaData
      if (this.condition.metaData != null) {
        delete this.condition.metaData
      }

      // TODO this should be moved to the TimerCondition component
      switch (this.conditionSelection) {
        // Will change with more, not finished
        case 'time':
          this.condition.type = 'timers'
          this.condition.timezone = moment.tz.guess();
          break
      }

      // clientId and siteId will be added by the proxy (PHP)
      let rule = {
        name: this.ruleProps.name,
        iconKey: this.ruleProps.iconKey,
        deactivated: this.ruleProps.deactivated,
        condition: this.condition,
        action: this.action,
        synced: 'PENDING'
      }

      if (this.ruleId) {
        // update existing rule
        this.$rhRequest.sendPost({
          endpoint: "rule/update",
          data: rule,
          params: {id: this.ruleId}
        }, (resp) => {
          if (resp.data.code === 0) {
            this.$root.bisatoast.success({
              message: this.$t('add-rule-dialog.save.rule-updated', {name: this.ruleProps.name}),
              showCloseBtn: true
            })
            // notifies RulesCard to reload the rule items
            this.$root.$emit('reloadItems')
            this.$root.bisadialog.toggle('addRule')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          this.loading = false
        }, (err) => {
          console.error(err)
          this.loading = false
          if (err.response.data.code === 580) {
            this.$root.bisatoast.warning({message: this.$t('add-rule-dialog.save.gateway-offline'), showCloseBtn: true})
            this.$root.bisadialog.toggle('addRule')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          // notifies RulesCard to reload the rule items
          this.$root.$emit('reloadItems')
        })
      } else {
        // create new rule
        this.$rhRequest.sendPost({
          endpoint: "rule/add",
          data: rule
        }, (resp) => {
          if (resp.data.code === 0) {
            this.$root.bisatoast.success({
              message: this.$t('add-rule-dialog.save.rule-created', {name: this.ruleProps.name}),
              showCloseBtn: true
            })
            // notifies RulesCard to reload the rule items
            this.$root.$emit('reloadItems')
            this.$root.bisadialog.toggle('addRule')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          this.loading = false
        }, (err) => {
          console.error(err)
          this.loading = false
          if (err.response.data.code === 580) {
            this.$root.bisatoast.warning({message: this.$t('add-rule-dialog.save.gateway-offline'), showCloseBtn: true})
            this.$root.bisadialog.toggle('addRule')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          // notifies RulesCard to reload the rule items
          this.$root.$emit('reloadItems')
        })
      }
    },

    /**
     * deletes a rule
     */
    deleteRule() {
      if (!this.isSiteAdmin) {
        return
      }
      let rule = this.data?.rule
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 400,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('rules-card.delete-rule.confirm', {name: this.data.rule.attributes.name}),
        confirmLabel: this.$t('rules-card.delete-rule.button')
      })
      this.$root.$on('dialogConfirmed', () => {
        this.$rhRequest.sendDelete({
          endpoint: 'rule/delete',
          params: {id: rule.id}
        }, () => {
          this.$root.bisatoast.success({
            message: this.$t('rules-card.delete-rule.success', {name: rule.attributes.name}),
            showCloseBtn: true
          })
          // notifies RulesCard to reload the rule items
          this.$root.$emit('reloadItems')
          this.$root.bisadialog.toggle('addRule')
        }, (error) => {
          console.error(error)
          if (error.response.data.code === 580) {
            this.$root.bisatoast.warning({message: this.$t('add-rule-dialog.save.gateway-offline'), showCloseBtn: true})
            this.$root.bisadialog.toggle('addRule')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
        })
        this.$root.$off('dialogConfirmed')
      })
    },

    /**
     * gets a single device actuator item by device id
     * @param deviceId
     */
    getDeviceActuatorItem(deviceId) {
      this.loadingAction = true
      this.$rhRequest.sendGet({
        endpoint: "rule/device-actuator-items",
        params: {
          "deviceId": deviceId.toString()
        }
      }, (resp) => {
        this.deviceSelection = Object.values(resp?.data?.data).pop()
        this.loadingAction = false
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
        this.loadingAction = false
      })
    },

    /**
     * synchronises a rule with the Gateway
     */
    syncRule() {
      this.syncing = true
      this.$rhRequest.sendGet({
        endpoint: "rule/sync-rule",
        params: {id: this.ruleId}
      }, () => {
        this.$root.bisatoast.success({message: this.$t('add-rule-dialog.sync-rule.success'), showCloseBtn: true})
        this.syncing = false
        this.save()
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('add-rule-dialog.sync-rule.error'), showCloseBtn: true})
        this.syncing = false
      })
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addRule')
    }
  },

  watch: {
    step(value) {
      switch (value) {
        case 1:
          this.title = this.$t('add-rule-dialog.overview.title')
          break
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          this.title = this.$t('add-rule-dialog.condition.title')
          break
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
          this.title = this.$t('add-rule-dialog.action.title')
          break
        default:
          this.title = this.$t('add-rule-dialog.overview.title')
          break
      }
    }
  }
};
</script>
