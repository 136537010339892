<template>
  <v-skeleton-loader v-if="loading"
                     type="card"
                     class="mb-5"
                     height="320"/>
  <v-carousel v-else
              cycle
              height="inherit"
              continuous
              hide-delimiter-background
              show-arrows-on-hover
              :show-arrows="offers?.length > 1"
              :hide-delimiters="offers?.length < 2">
    <v-carousel-item v-for="offer in offers" v-bind:key="offer.id">
      <offer-card :content="offer"
                  extended/>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";
import OfferCard from "@/templates/components/living/OfferCard.vue";

export default {
  name: "OffersCarousel",
  props: {
    filter: {
      type: String, // OVERVIEW for /home or ENERGY_PAGE for /homeEnergy
      default: 'OVERVIEW'
    },
  },
  components: {OfferCard},

  data() {
    return {
      timer: null,
      refreshRate: config.dataRefreshRate,
      offers: [],
      loading: false
    }
  },

  methods: {
    /**
     * gets offers from the API
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: "content-service/get-relevant",
        params: {
          type: "MARKET",
          filter: this.filter // only includes entries which are flagged for display on the calling component
        }
      }, (response) => {
        this.offers = response?.data?.data
        if (this.offers.length > 0) {
          this.$emit('hide-offers', false)
        } else {
          this.$emit('hide-offers', true)
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.$emit('hide-offers', true)
        this.loading = false
      })
    },

    /**
     * returns the v-img source for an image id
     *
     * @param imageId
     * @returns {string}
     */
    getImgSrc(imageId) {
      return config.portalApi + 'content-service/get-image?id=' + imageId
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },
    loadCachedData() {
      this.$caching.getData('offers').then(offers => {
        if (offers !== -1) {
          this.offers = offers
          this.loading = false
          if (this.offers && this.offers.length > 0) {
            this.$emit('hide-offers', false)
          } else {
            this.$emit('hide-offers', true)
          }
        }
      })
    }
  },

  mounted() {
    this.loading = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
      () => this.getData(false),
      () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    offers(newVal) {
      this.$caching.saveData('offers', newVal)
    }
  }
}
</script>
